import styled from 'styled-components';
import { primaryMerBlue } from 'styles/variables';

const Container = styled.div<{ $size: string }>`
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};

    svg {
        z-index: 190;
        animation: rotate 2s linear infinite;

        circle {
            stroke: ${primaryMerBlue};
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }

        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes dash {
            0% {
                stroke-dasharray: 1, 150;
                stroke-dashoffset: 0;
            }
            50% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -35;
            }
            100% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -124;
            }
        }
    }
`;

type LoadingIndicatorProps = {
    size?: string;
    className?: string;
};

function LoadingSpinner({ size = '1.5rem', className }: LoadingIndicatorProps): JSX.Element {
    return (
        <Container $size={size} data-testid="loadingSpinnerContainer" className={className}>
            <svg viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
        </Container>
    );
}

export default LoadingSpinner;
