import DateRangeFields from './DateRangeFields';
import DateRangePicker from './DateRangePicker';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { panelBackgroundColorGrey } from 'styles/variables';

const CalenderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background: ${panelBackgroundColorGrey};
`;

export type DateRangeFormat = {
    from: string;
    to: string;
};

type DateRangeProps = {
    range: DateRangeFormat;
    setRange: Dispatch<SetStateAction<DateRangeFormat>>;
    hideOptionalText?: boolean;
};

function DateRange({ range, setRange, hideOptionalText }: DateRangeProps): JSX.Element {
    return (
        <>
            <DateRangeFields range={range} setRange={setRange} hideOptionalText={hideOptionalText} />
            <CalenderContainer>
                <DateRangePicker range={range} setRange={setRange} />
            </CalenderContainer>
        </>
    );
}

export default DateRange;
