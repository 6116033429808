import RouteContentWrapper from './RouteContentWrapper';
import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import FullPageLoadingIndicator from 'components/indicators/FullPageLoadingIndicator';
import { PATH } from 'constants/paths';
import { useAppInsights } from 'contexts/AppInsightsContext';
import useAuthentication from 'hooks/useAuthentication';
import { useLanguage } from 'hooks/useLanguage';
import i18n from 'i18n';
import ErrorPageWithoutUserMenu from 'pages/ErrorPageWithoutUserMenu';
import SignUpPage from 'pages/SignUpPage';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ELocale } from 'types';

const IS_LOADING_CLASS = 'is-loading';

function AppRoutesWrapper(): JSX.Element {
    const { inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { pathname } = useLocation();
    const { appInsights } = useAppInsights();
    const location = useLocation();

    const { getValidBULanguage } = useLanguage();

    const { existsAccount, loginRedirectRegular, registerLogoutCallback, logoutRedirectRegular, getAccount } =
        useAuthentication();

    const [error, setError] = useState<AuthError>();

    if (appInsights && error) {
        appInsights.trackException({
            exception: new Error(error.name),
            severityLevel: 3,
            properties: {
                url: `${location.pathname}${location.search}${location.hash}`,
                source: 'Azure AD B2C',
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                subError: error.subError,
                stack: error.stack,
            },
        });
    }

    useEffect(() => {
        if (localStorage.getItem('locale') !== i18n.language) {
            i18n.changeLanguage(getValidBULanguage(i18n.language as ELocale));
        }
        if (!isAuthenticated && inProgress === InteractionStatus.None && !existsAccount() && pathname !== PATH.SIGNUP) {
            if (!error) {
                loginRedirectRegular();
            }
        } else if (existsAccount() && isAuthenticated && pathname === PATH.SIGNUP) {
            logoutRedirectRegular(false);
        } else if (
            isAuthenticated &&
            inProgress === InteractionStatus.None &&
            import.meta.env.VITE_APP_AZUREAD_AUTHORITY_CHANGEEMAIL?.toLocaleLowerCase().includes(
                getAccount()?.tenantId || '',
            )
        ) {
            logoutRedirectRegular(false);
        } else if (inProgress === InteractionStatus.HandleRedirect) {
            instance
                .handleRedirectPromise()
                .then((redirectResponse) => {
                    if (!redirectResponse) {
                        return;
                    }
                    const responseAuthority = redirectResponse.authority.toLowerCase();
                    if (
                        responseAuthority.startsWith(
                            import.meta.env.VITE_APP_AZUREAD_AUTHORITY_CHANGEEMAIL?.toLocaleLowerCase() || '',
                        )
                    ) {
                        logoutRedirectRegular(false);
                    }
                })
                .catch((e) => {
                    setError(e);
                });
        }
        registerLogoutCallback();
    }, [
        error,
        isAuthenticated,
        inProgress,
        existsAccount,
        loginRedirectRegular,
        registerLogoutCallback,
        pathname,
        logoutRedirectRegular,
        instance,
        getAccount,
        getValidBULanguage,
    ]);

    if (pathname === PATH.SIGNUP) return <SignUpPage />;

    if (isAuthenticated && inProgress === InteractionStatus.None) {
        return <RouteContentWrapper />;
    }
    document.documentElement.classList.add(IS_LOADING_CLASS);

    if (error) {
        return <ErrorPageWithoutUserMenu />;
    }

    return <FullPageLoadingIndicator />;
}

export default AppRoutesWrapper;
