import SadFace from 'assets/illustrations/sadface.svg?react';
import Button from 'components/clickables/Button';
import MessageBox from 'components/info/MessageBox';
import { useAppInsights } from 'contexts/AppInsightsContext';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonSize, ButtonVariant, EPageErrorType } from 'types';

export type ErrorPageProps = {
    Illustration?: React.ReactNode;
    refresh?: boolean;
    home?: boolean;
    displayBorder?: boolean;
    type: EPageErrorType;
};

const StyledButton = styled(Button)`
    margin-top: 1rem;
`;

function ErrorPage({
    Illustration,
    type,
    refresh = false,
    home = false,
    displayBorder = true,
}: ErrorPageProps): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const onRefresh: () => void = () => {
        if (home) {
            navigate('/');
        }
        if (refresh) {
            navigate(0);
        }
    };

    const { appInsights } = useAppInsights();

    const track = (errorText: string): void => {
        appInsights?.trackException({
            exception: new Error(errorText),
            severityLevel: 3,
            properties: {
                url: `${location.pathname}${location.search}${location.hash}`,
                source: 'ErrorPage',
            },
        });
    };

    if (appInsights) {
        if (type === EPageErrorType.BROKEN_URL) {
            track('Invalid URL');
        } else if (type === EPageErrorType.NO_PERMISSION) {
            track('No permission to view page');
        } else if (type === EPageErrorType.BROKEN_INVITATION_URL) {
            track('Broken invitation url');
        }
    }

    let title = '';
    let description = '';
    switch (type) {
        case EPageErrorType.CRITICAL_ERROR_NO_MENU:
            title = t('page.criticalError.title');
            description = t('page.criticalError.description');
            break;
        case EPageErrorType.BROKEN_URL:
            title = t('page.brokenUrlError.title');
            description = t('page.brokenUrlError.description');
            break;
        case EPageErrorType.NO_PERMISSION:
            title = t('page.accessDeniedError.title');
            description = t('page.accessDeniedError.description');
            break;
        case EPageErrorType.BROKEN_INVITATION_URL:
            title = t('page.brokenInvitationUrlError.title');
            description = t('page.brokenInvitationUrlError.description');
            break;
        default:
            title = t('page.error.title');
            description = t('page.error.description');
    }
    return (
        <MessageBox
            Illustration={Illustration ?? <SadFace />}
            title={title}
            description={description}
            displayBorder={displayBorder}
            dataTestId="errorPageMessageBox"
        >
            {(refresh || home) && (
                <StyledButton onClick={onRefresh} variant={ButtonVariant.PRIMARY} size={ButtonSize.DEFAULT}>
                    {t('general.refresh')}
                </StyledButton>
            )}
        </MessageBox>
    );
}

export default ErrorPage;
