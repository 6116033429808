import useLocalState from './useLocalState';
import useSignalR from './useSignalR';
import { useQueries } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import useApi from 'hooks/useApi';
import { useCallback, useEffect, useRef } from 'react';
import {
    EConnectorStatus,
    IConnectorStatusNotification,
    IConnectorStatusNotificationData,
    IProperty,
} from 'types/facility';
import { batchArray, getSitesFromPropertyList } from 'utils/connectorStatus';
import { mapStatuses } from 'utils/facility';

export type Connector = {
    evseId: string;
    status: string;
};

export type UseConnectorStatusResponse = {
    connectors?: Connector[];
    isLoading: boolean;
    error?: ApiError;
};

export type ConnectorStatusResult = {
    connectors?: Connector[];
};

type UseConnectorStatusBodyProps = {
    siteIds: string[];
};

const useConnectorStatus = (properties: IProperty[]): UseConnectorStatusResponse => {
    const {
        actions: { setConnectorState },
    } = useLocalState();

    const { post } = useApi<ConnectorStatusResult, UseConnectorStatusBodyProps>();
    const url = `${GENERAL_BFF_PREFIX}/v1/connector-status`;

    const sitesBatches = batchArray(getSitesFromPropertyList(properties), 10);

    const fetcher: (siteIds: string[]) => Promise<ConnectorStatusResult> = (siteIds) =>
        post(url, {
            siteIds,
        });

    const results = useQueries({
        queries: sitesBatches.map((sitesBatch) => ({
            queryKey: [url, sitesBatch.join(',')],
            queryFn: () => fetcher(sitesBatch),
        })),
    });

    const isLoading = results.some((result) => result.isFetching);

    const processedUpdatesRef = useRef(new Set<string>());

    const { joinGroup, connection } = useSignalR<string, IConnectorStatusNotification>({
        listenerName: 'newStatus',
        onListener: useCallback(
            (payload) => {
                if (payload) {
                    const parsedData: IConnectorStatusNotificationData = JSON.parse(payload.Data);
                    setConnectorState({
                        evseId: parsedData.EvseIdentityKey,
                        status: mapStatuses(parsedData.SocketStatus),
                    });
                }
            },
            [setConnectorState],
        ),
    });

    useEffect(() => {
        results.forEach((query, index) => {
            if (
                query.isSuccess &&
                query.data.connectors &&
                connection &&
                !processedUpdatesRef.current.has(sitesBatches[index].toString())
            ) {
                query.data.connectors.forEach((connector: Connector) => {
                    setConnectorState({ evseId: connector.evseId, status: connector.status as EConnectorStatus });
                    processedUpdatesRef.current.add(sitesBatches[index].toString());
                });
                if (query.data.connectors.length) {
                    joinGroup(
                        'JoinB2BGroup',
                        query.data.connectors.map((connector: Connector) => connector.evseId).join(','),
                    );
                }
            }
        });
    }, [results, setConnectorState, sitesBatches, joinGroup, connection]);

    return {
        isLoading,
    };
};

export default useConnectorStatus;
