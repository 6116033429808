import { MenuListType } from './NavigationMenu';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { darkerGrey, font, lightBlue, primaryMerBlue, white } from 'styles/variables';

export const IconStyle = `    
color: ${darkerGrey};
font-family: ${font.body};
font-size: ${font.size.m};
position: relative;
height: 2.75rem;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background: none;
text-decoration: none;
outline: none;
display: inline-block;
margin: auto; 
border: none;

&:hover,
&:focus,
&:active {
    background: ${white};
    outline: none;
}

&.active {
    color: ${primaryMerBlue};
    background: ${lightBlue};
}
`;

const MenuIcon = styled(NavLink)`
    ${IconStyle}
    padding: 0 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

type MenuIconButton = {
    item: MenuListType;
    isActive: boolean;
};

function MenuIconNoItems({ item: { path, icon, name }, isActive }: MenuIconButton): JSX.Element {
    return (
        <MenuIcon data-testid={`menuLink_${name}`} to={path} className={isActive ? 'active' : ''}>
            {icon}
        </MenuIcon>
    );
}

export default MenuIconNoItems;
