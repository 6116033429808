import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderColorBlue, borderRadiusM, font, spaceS, spaceXs } from 'styles/variables';

const LoadingContainer = styled.section<{ $border: boolean; $height: string }>`
    padding: ${spaceS};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${spaceXs};
    height: ${({ $height }) => $height};
    border: ${({ $border }) => ($border ? `1px solid ${borderColorBlue}` : 'none')};
    border-radius: ${borderRadiusM};
    margin: auto;
    width: 100%;
`;

const Text = styled.p`
    margin: ${spaceS} 0;
    text-align: center;
    line-height: ${font.lineHeight.l};
`;

type LoadingIndicatorWithTextProps = {
    loadingText?: string;
    className?: string;
    displayText?: boolean;
    displayBorder?: boolean;
    height?: string;
};

export function LoadingIndicator({
    loadingText,
    className = '',
    displayText = true,
    displayBorder = true,
    height = '23.75rem',
}: LoadingIndicatorWithTextProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <LoadingContainer
            className={className}
            data-testid="loadingIndicatorContainer"
            $border={displayBorder}
            $height={height}
        >
            <LoadingSpinner size="3rem" />
            {displayText && <Text>{loadingText ?? t('general.loadingText.loading')}</Text>}
        </LoadingContainer>
    );
}
