import { IconStyle } from './MenuIconNoItems';
import MenuItem from './MenuItem';
import { MenuListType } from './NavigationMenu';
import { useState } from 'react';
import styled from 'styled-components';
import { collapsedMenuWidth, lighterGrey, menuBoxShadow, red, spaceS } from 'styles/variables';

const MenuIcon = styled.button`
    ${IconStyle};
    position: relative;
    &.dot::after {
        position: absolute;
        right: ${spaceS};
        content: ' ';
        display: block;
        width: 5px;
        height: 5px;
        background: ${red};
        border-radius: 100%;
    }
`;

const Icons = styled.div`
    width: ${collapsedMenuWidth};
    padding-left: 0;
    padding-top: 5px;
`;

const MenuChildrenContainer = styled.div`
    position: absolute;
    width: 198px;
    top: 0;
    left: ${collapsedMenuWidth};
    white-space: nowrap;
    background-color: ${lighterGrey};
    box-shadow: ${menuBoxShadow};
`;

type MenuIconButton = {
    isActive: boolean;
    item: MenuListType;
};

function MenuIconWithItems({ item: { icon, showDot, items, name }, isActive }: MenuIconButton): JSX.Element {
    const [hover, setHover] = useState<boolean>(false);

    return (
        <MenuIcon
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className={`${isActive ? 'active' : ''} ${showDot ? 'dot' : ''}`}
            data-testid={`menu_${name}`}
        >
            <Icons>{icon}</Icons>
            {hover ? (
                <MenuChildrenContainer>
                    {items.map((item) => {
                        return (
                            item.visible && (
                                <MenuItem collapsed key={item.path} item={item} onClick={() => setHover(false)} />
                            )
                        );
                    })}
                </MenuChildrenContainer>
            ) : null}
        </MenuIcon>
    );
}

export default MenuIconWithItems;
