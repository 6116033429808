import PaginationBar from './PaginationBar';
import TotalCount from './TotalCount';
import styled from 'styled-components';
import { spaceL } from 'styles/variables';
import { PaginatedResponse } from 'types';

export const PaginationTotalContainer = styled.div`
    display: flex;
    margin-top: ${spaceL};
`;

type ListFooterProps = {
    data: PaginatedResponse;
    handlePagination: (page: number) => void;
    pageNumber: number;
};
function ListFooter(props: ListFooterProps): JSX.Element {
    const { data, handlePagination, pageNumber } = props;
    return (
        <PaginationTotalContainer>
            <TotalCount total={data.totalNumberOfElements} />
            <PaginationBar
                onChangePage={handlePagination}
                pageNumber={pageNumber}
                totalNumberOfPages={data.totalNumberOfPages}
                isLastPage={data.isLastPage}
            />
        </PaginationTotalContainer>
    );
}

export default ListFooter;
