import { DateRangeFormat } from './DateRange';
import Form from './Form';
import TextInput from './TextInput';
import { MAX_DATE_RANGE } from 'constants/general';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useDate } from 'hooks/useDate';
import useDateValidation from 'hooks/useDateValidation';
import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { errorColor, font, spaceL, spaceS } from 'styles/variables';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 ${spaceL};
`;

const RangeErrorText = styled.p`
    color: ${errorColor};
    grid-column: span 2;
    margin-top: -${spaceS};
    font-size: ${font.size.s};
`;

type DateRangeFieldsProps = {
    range: DateRangeFormat;
    setRange: Dispatch<SetStateAction<DateRangeFormat>>;
    hideOptionalText?: boolean;
};

function DateRangeFields({ range, setRange, hideOptionalText }: DateRangeFieldsProps): JSX.Element {
    const { t } = useTranslation();
    const [rangeErrorText, setRangeErrorText] = useState<string>();

    const { isDateAfterToday, isFromDateAfterToDate, isValidDate, isValidDateRange } = useDateValidation();
    const { dateDelimiterRegExp, datePlaceholder } = useCountrySpecificContent();
    const formMethods = useForm();
    const { autoAddSeparator, shouldRemoveSeparator } = useDate();

    const fromError = useMemo(() => {
        if (range.from) {
            if (!isValidDate(range.from)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.invalidDate'),
                };
            }
            if (isDateAfterToday(range.from)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.dateAfterToday'),
                };
            }
        }
        return undefined;
    }, [range.from, t, isDateAfterToday, isValidDate]);

    const toError = useMemo(() => {
        if (range.to) {
            if (!isValidDate(range.to)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.invalidDate'),
                };
            }
            if (isDateAfterToday(range.to)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.dateAfterToday'),
                };
            }
        }
        return undefined;
    }, [range.to, t, isDateAfterToday, isValidDate]);

    const rangeError = useMemo(() => {
        if (!fromError && !toError) {
            if (range.from.length === 10 && range.to.length === 10) {
                if (isFromDateAfterToDate(range.from, range.to)) {
                    setRangeErrorText(t('form.dateRange.invalidRange'));
                    return {
                        type: 'match',
                        message: ' ',
                    };
                }
                if (!isValidDateRange(range.from, range.to)) {
                    setRangeErrorText(
                        t('form.dateRange.tooManyDaysInRange', {
                            maxDays: MAX_DATE_RANGE,
                        }),
                    );
                    return {
                        type: 'match',
                        message: ' ',
                    };
                }
            } else if ((range.from && !range.to) || (!range.from && range.to)) {
                setRangeErrorText(t('form.dateRange.invalidRange'));
                return {
                    type: 'match',
                    message: ' ',
                };
            }
        }
        setRangeErrorText(undefined);
        return undefined;
    }, [fromError, toError, range.from, range.to, isFromDateAfterToDate, isValidDateRange, t]);
    const clearFromValue = (): void => {
        setRange({ from: '', to: range.to });
    };

    const clearToValue = (): void => {
        setRange({ from: range.from, to: '' });
    };
    const handleFromInputChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
        const inputValue = e.currentTarget.value.replace(dateDelimiterRegExp, '');
        const dateString = autoAddSeparator(inputValue);

        setRange({ from: dateString, to: range.to });
        formMethods.setValue('dateFrom', dateString);
    };

    const handleToInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const inputValue = e.currentTarget.value.replace(dateDelimiterRegExp, '');
        const dateString = autoAddSeparator(inputValue);

        setRange({ from: range.from, to: dateString });
        formMethods.setValue('dateTo', dateString);
    };

    const handleKeyPressFunc = (e: {
        which: number;
        currentTarget: { value: string };
        preventDefault: () => void;
    }): void => {
        if (e.which === 8) {
            if (shouldRemoveSeparator(e.currentTarget.value)) {
                e.currentTarget.value = e.currentTarget.value.slice(0, e.currentTarget.value.length - 1);
            }
        } else if (e.which === 189 || e.which === 173 || e.which === 190) {
            // prevent user from adding "-" and "."
            // character "dash" has key code 173 in Firefox and 189 in other browsers
            // character "." has key code 190
            e.preventDefault();
        }
    };

    useEffect(() => {
        formMethods.setValue('dateFrom', range.from);
        formMethods.setValue('dateTo', range.to);
    }, [formMethods, range.from, range.to]);

    return (
        <Form formMethods={formMethods}>
            <Container>
                <TextInput
                    dataTestId="dateFrom"
                    {...formMethods.register('dateFrom')}
                    label={t('form.dateRange.from')}
                    placeholder={datePlaceholder}
                    maxLength={10}
                    onChange={handleFromInputChange}
                    onKeyDown={handleKeyPressFunc}
                    fieldError={fromError ?? rangeError}
                    hideOptionalText={hideOptionalText}
                    onClearFieldClick={clearFromValue}
                />
                <TextInput
                    dataTestId="dateTo"
                    {...formMethods.register('dateTo')}
                    label={t('form.dateRange.to')}
                    placeholder={datePlaceholder}
                    maxLength={10}
                    onChange={handleToInputChange}
                    onKeyDown={handleKeyPressFunc}
                    fieldError={toError ?? rangeError}
                    hideOptionalText={hideOptionalText}
                    onClearFieldClick={clearToValue}
                />
                <RangeErrorText data-testid="dateRangeError">{rangeErrorText}</RangeErrorText>
            </Container>
        </Form>
    );
}

export default DateRangeFields;
