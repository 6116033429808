import Button from './Button';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ButtonSize, ButtonVariant } from 'types';

export const StyledButton = styled(Button)`
    &:hover {
        background: transparent;
    }

    input[type='file'] {
        display: none;
    }
`;

type BrowseFileButtonProps = {
    children?: React.ReactNode;
    variant?: ButtonVariant;
    size?: ButtonSize;
    id?: string;
    name: string;
    disabled?: boolean;
    className?: string;
    setSelectedFile?: (files: File[]) => void;
    multiple: boolean;
    accept?: string;
    clearFile?: boolean;
    dataTestId?: string;
};

function BrowseFileButton({
    id,
    name,
    children,
    disabled,
    multiple,
    accept,
    variant = ButtonVariant.TEXT,
    size = ButtonSize.DEFAULT,
    className = '',
    clearFile,
    setSelectedFile,
    dataTestId,
}: BrowseFileButtonProps): JSX.Element {
    const fileRef = useRef<HTMLInputElement | null>(null);

    const onBrowseClick = (): void => {
        fileRef.current?.click();
    };

    const onFileSelectChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { files } = e.target;
        if (files?.length) {
            const filesList = Array.from(files);

            if (setSelectedFile) {
                setSelectedFile(filesList);
            }
        }

        e.target.value = '';
    };
    useEffect(() => {
        if (clearFile) {
            if (fileRef.current) {
                fileRef.current.value = '';
            }
        }
    }, [clearFile]);

    return (
        <StyledButton disabled={disabled} className={className} onClick={onBrowseClick} variant={variant} size={size}>
            {children}
            <input
                id={id}
                data-testid={dataTestId}
                type="file"
                name={name}
                multiple={multiple}
                onChange={onFileSelectChange}
                ref={fileRef}
                accept={accept}
            />
        </StyledButton>
    );
}

export default BrowseFileButton;
