import SubscriptionDetailsPanel from './subscriptionDetails/SubscriptionDetailsPanel';
import SubscriptionStatusPopUp from './SubscriptionStatusPopUp';
import ExportButton from 'components/clickables/ExportButton';
import SearchInput from 'components/forms/SearchInput';
import StatusPill from 'components/info/StatusPill';
import TextEllipsisComponent, { TextEllipsis } from 'components/info/TextEllipsisComponent';
import SidePanel from 'components/layout/SidePanel';
import GridList, { GridListHeader, GridListItem, GridListItemButton, Header } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import SortableColumnHeader from 'components/lists/SortableColumnHeader';
import { PAGE_SIZE } from 'constants/general';
import useBusinessId from 'hooks/useBusinessId';
import { useDate } from 'hooks/useDate';
import { useExport } from 'hooks/useExport';
import useSubscriptions from 'hooks/useSubscriptions';
import useSubscriptionsExport from 'hooks/useSubscriptionsExport';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { darkGrey, spaceM, spaceS } from 'styles/variables';
import { EExportFileType, ESortingOrder } from 'types';
import { ESubscriptionSortingColumns, ESubscriptionStatus, Subscription } from 'types/subscription';
import { ETrackingEvent, ETrackingOrigin } from 'types/tracking';

const UtilityBar = styled.div`
    display: flex;
    gap: ${spaceS};
    margin-bottom: ${spaceM};
`;

const gridColumn = '7rem minmax(3rem, 1fr) 7rem minmax(3rem, 1fr) 9.375rem minmax(3rem, 1fr)';

const StyledTextEllipsis = styled(TextEllipsis)`
    color: ${darkGrey};
`;

function SubscriptionList(): JSX.Element {
    const sidePanelRef = useRef<HTMLDivElement>(null); // Ref for the sidebar
    const productListRef = useRef<HTMLDivElement>(null); // Ref for the product list
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();

    const { formatDate } = useDate();
    const { activeParentBusinessId } = useBusinessId();

    const [searchParams, setSearchParams] = useSearchParams();
    const [sorting, setSorting] = useState({
        sortColumn: ESubscriptionSortingColumns.CREATION_TIME,
        sortDirection: ESortingOrder.DESC,
    });
    const searchTab = searchParams.get('tab');
    const searchPageNumber = searchParams.get('pageNumber');
    const searchTerm = searchParams.get('searchTerm');

    const querySearchParam = searchParams;

    const firstRender = useRef(searchTerm);
    const [pageNumber, setPageNumber] = useState<number>(searchPageNumber ? Number(searchPageNumber) : 1);

    useEffect(() => {
        if (searchTab != null) {
            searchParams.delete('tab');
            setSearchParams(searchParams);
            setPageNumber(1);
        }
    }, [searchTab, searchParams, setSearchParams]);

    useEffect(() => {
        if (pageNumber === 1) {
            querySearchParam.set('pageNumber', '1');
        } else {
            querySearchParam.set('pageNumber', String(pageNumber));
        }
        setSearchParams(querySearchParam);
    }, [pageNumber, querySearchParam, setSearchParams]);

    useEffect(() => {
        if (searchTerm !== firstRender.current) {
            setPageNumber(1);
        }
    }, [searchTerm]);

    const [fileType, setFileType] = useState<EExportFileType>(EExportFileType.EXCEL);

    const subscriptionsQueryData = {
        parentBusinessId: activeParentBusinessId,
        pageNumber: pageNumber - 1,
        pageSize: PAGE_SIZE,
        searchTerm: searchTerm ?? '',
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
        fileType,
    };

    const {
        subscriptionList,
        error: errorSubscriptions,
        isLoading,
        mutateSubscriptions,
    } = useSubscriptions(subscriptionsQueryData);
    const getExport = useSubscriptionsExport(subscriptionsQueryData);

    const haveSubscriptions = subscriptionList && subscriptionList.subscriptions.length >= 1;
    const { startExport, somethingToExport } = useExport(haveSubscriptions);

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    const getSortingDirection = (column: ESubscriptionSortingColumns): ESortingOrder => {
        const direction = sorting.sortColumn === column ? sorting.sortDirection : undefined;
        return direction as ESortingOrder;
    };

    const handleOnSortChange = (sortColumn: ESubscriptionSortingColumns, sortDirection: ESortingOrder): void => {
        setSorting({ sortColumn, sortDirection });
    };

    const openPanel = (sub: Subscription, event: React.MouseEvent): void => {
        event.stopPropagation();
        setSelectedSubscription(sub);
        setIsOpen(true);
    };

    const closePanel = (): void => {
        setIsOpen(false);
    };

    useEffect(() => {
        function handler(e: MouseEvent): void {
            const target = e.target as HTMLElement;
            const closestUl = target.closest('ul');

            if (
                !sidePanelRef?.current?.contains(e.target as Node) &&
                target.getAttribute('data-testid') !== 'languageSelector' &&
                closestUl?.getAttribute('data-testid') !== 'languageList'
            ) {
                const closestLi = target.closest('li');
                if (productListRef.current && closestLi) {
                    const parentList = closestLi.parentElement;

                    if (parentList) {
                        const allListItems = Array.from(parentList.children);
                        const index = allListItems.indexOf(closestLi);

                        if (index === 0) {
                            setSelectedSubscription(undefined);
                            closePanel();
                        }
                    }
                } else if (!productListRef.current?.contains(e.target as Node)) {
                    setSelectedSubscription(undefined);
                    closePanel();
                }
            }
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [sidePanelRef]);

    return (
        <>
            <UtilityBar>
                <SearchInput placeholder={t('subscription.subscriptionList.searchPlaceHolder')} />
                <ExportButton
                    setFileType={setFileType}
                    isLoading={getExport.isPending}
                    getExport={() => {
                        ReactGA.event(ETrackingEvent.EXPORT_FILE, {
                            origin: ETrackingOrigin.SUBSCRIPTION,
                        });
                        startExport(() => getExport.mutateAsync(), haveSubscriptions);
                    }}
                    showMessage={!somethingToExport}
                />
            </UtilityBar>
            <GridList
                ref={productListRef}
                gridColumns={gridColumn}
                clickable
                dataTestId="subscriptionsList"
                showEmptyListMessage={subscriptionList?.subscriptions.length === 0 && !isLoading}
                isLoading={!haveSubscriptions && isLoading}
                hasError={!!errorSubscriptions}
            >
                <GridListHeader>
                    <SortableColumnHeader
                        sortingDirection={getSortingDirection(ESubscriptionSortingColumns.STATUS)}
                        onSortChange={(direction) =>
                            handleOnSortChange(ESubscriptionSortingColumns.STATUS, direction as ESortingOrder)
                        }
                        infoButton={<SubscriptionStatusPopUp gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION} />}
                        text={t('general.status')}
                    />
                    <Header>{t('general.name')}</Header>
                    <Header>{t('general.licencePlate')}</Header>
                    <Header>{t('general.reference')}</Header>
                    <SortableColumnHeader
                        sortingDirection={getSortingDirection(ESubscriptionSortingColumns.CREATION_TIME)}
                        onSortChange={(direction) =>
                            handleOnSortChange(ESubscriptionSortingColumns.CREATION_TIME, direction as ESortingOrder)
                        }
                        text={t('general.startDate')}
                    />
                    <Header>{t('general.subscription')}</Header>
                </GridListHeader>
                {haveSubscriptions && !errorSubscriptions && (
                    <>
                        {subscriptionList.subscriptions.map((sub) => (
                            <GridListItem
                                key={sub.id}
                                className={selectedSubscription?.id === sub.id ? 'selected' : ''}
                            >
                                <GridListItemButton key={sub.id} onClick={(event) => openPanel(sub, event)}>
                                    <StatusPill status={sub.status as ESubscriptionStatus} />
                                    {!sub.driverName ? (
                                        <StyledTextEllipsis>{t('subscription.unpersonalised')}</StyledTextEllipsis>
                                    ) : (
                                        <TextEllipsisComponent tooltipText={sub.driverName}>
                                            {sub.driverName}
                                        </TextEllipsisComponent>
                                    )}
                                    <TextEllipsis>{sub.licencePlate}</TextEllipsis>
                                    <TextEllipsisComponent tooltipText={sub.reference}>
                                        {sub.reference}
                                    </TextEllipsisComponent>
                                    <p>{formatDate(sub.startDate)}</p>
                                    <TextEllipsisComponent tooltipText={sub.productType}>
                                        {sub.productType}
                                    </TextEllipsisComponent>
                                </GridListItemButton>
                            </GridListItem>
                        ))}
                    </>
                )}
            </GridList>
            {haveSubscriptions && !isLoading && (
                <ListFooter data={subscriptionList} handlePagination={handlePagination} pageNumber={pageNumber} />
            )}
            <SidePanel
                isOpen={isOpen}
                onClose={closePanel}
                title={t('subscription.details.subscriptionsTitle')}
                ref={sidePanelRef}
            >
                {selectedSubscription && (
                    <SubscriptionDetailsPanel
                        selectedSubscription={selectedSubscription}
                        sidePanelOpen={isOpen}
                        onSave={() => mutateSubscriptions()}
                    />
                )}
            </SidePanel>
        </>
    );
}

export default SubscriptionList;
