import styled from 'styled-components';
import { font } from 'styles/variables';

const dotSize = '0.375rem'; // 6px

const Item = styled.div<{ $haveDot: boolean }>`
    display: flex;
    gap: ${dotSize};
    p {
        text-align: ${({ $haveDot }) => ($haveDot ? 'left' : 'center')};
        font-size: ${font.size.xs};
        line-height: ${font.lineHeight.m};
        margin: 0;
        span {
            display: block;
        }
    }
`;
const Dot = styled.div<{ color: string }>`
    margin-top: ${dotSize};
    width: ${dotSize};
    height: ${dotSize};
    background: ${({ color }) => color};
    flex-shrink: 0;
`;

type KpiFooterTextProps = {
    dotColor?: string;
    rowOne: string | JSX.Element;
    rowTwo: string | number;
};

function KpiFooterText({ dotColor, rowOne, rowTwo }: KpiFooterTextProps): JSX.Element {
    return (
        <Item $haveDot={!!dotColor}>
            {dotColor && <Dot color={dotColor} />}
            <p>
                <span>{rowOne}</span>
                <span>{rowTwo}</span>
            </p>
        </Item>
    );
}

export default KpiFooterText;
