import useAuthentication from './useAuthentication';
import { jwtDecode } from 'jwt-decode';
import { useMemo } from 'react';

type UserJwtProperties = {
    companyCode: string;
};

export const decodeToken = (token?: string): UserJwtProperties => {
    const decodedToken = (token ? jwtDecode(token) : '') as UserJwtProperties;
    return decodedToken;
};

const useToken = (): { businessUnitCode: string } => {
    const { getAccount } = useAuthentication();
    const account = getAccount();
    const decodedToken = useMemo(() => decodeToken(account?.idToken), [account?.idToken]);
    return {
        businessUnitCode: decodedToken.companyCode,
    };
};

export default useToken;
