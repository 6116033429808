import SubscriptionStatusPopUp from '../SubscriptionStatusPopUp';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import { EditDetailsSection } from 'components/elements/EditDetailsSection';
import Form from 'components/forms/Form';
import TextInput from 'components/forms/TextInput';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import { ClickableListItem, ListItem } from 'components/lists/DetailsList';
import StatusTag from 'components/visuals/StatusTag';
import { useToast } from 'contexts/Toast';
import useAuthorization from 'hooks/useAuthorization';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useDate } from 'hooks/useDate';
import useSubscription from 'hooks/useSubscription';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusM, licencePlateInputWidth, panelBackgroundColorGrey, spaceL, spaceS } from 'styles/variables';
import { ButtonType, ButtonVariant, EToastType } from 'types';
import { EBusinessFeature } from 'types/business';
import { ESubscriptionStatus, Subscription } from 'types/subscription';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin } from 'types/tracking';
import * as yup from 'yup';

const Container = styled.div`
    border-radius: ${borderRadiusM};
    padding: ${spaceL} 1.25rem;
    background-color: ${panelBackgroundColorGrey};
`;

const ButtonContainer = styled.section`
    display: flex;
    gap: ${spaceS};
    margin-top: ${spaceS};
`;

const LicencePlateInput = styled(TextInput)`
    max-width: ${licencePlateInputWidth};
`;
const Rfid = styled.span`
    span {
        display: block;
    }
`;

type SubscriptionDetailsPanelProps = {
    selectedSubscription: Subscription;
    sidePanelOpen: boolean;
    onSave: () => void;
};

const UpdateSubscriptionSchema = (
    licencePlateRegExp: RegExp,
): yup.ObjectSchema<Pick<Subscription, 'licencePlate' | 'reference'>> =>
    yup.object().shape({
        licencePlate: yup
            .string()
            .required('form.input.licencePlate.required')
            .matches(licencePlateRegExp, { message: 'form.input.licencePlate.validation' }),
        reference: yup.string().required('form.input.reference.required'),
    });

function SubscriptionDetailsPanel({
    selectedSubscription,
    sidePanelOpen,
    onSave,
}: SubscriptionDetailsPanelProps): JSX.Element {
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { formatDate } = useDate();

    const [editField, setEditField] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (!sidePanelOpen) {
            setEditField(undefined);
        }
    }, [sidePanelOpen]);

    const { subscription, isLoading, patchSubscription, error } = useSubscription({
        businessId: selectedSubscription.businessId,
        subscriptionId: selectedSubscription.id,
    });

    if (error) {
        addToast({ message: t('subscription.details.errorMessage'), type: EToastType.ERROR });
    }

    useEffect(() => {
        if (selectedSubscription.id !== subscription?.id) {
            setEditField(undefined);
        }
    }, [selectedSubscription, subscription]);

    const { checkAuthorizationFeature } = useAuthorization();
    const editAllowed =
        checkAuthorizationFeature(EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT) && subscription?.isActive === true;

    const { licencePlateRegExp, licencePlateMaxLength, licencePlateMinLength } = useCountrySpecificContent();

    const formMethods = useForm<Partial<Subscription>>({
        mode: 'onSubmit',
        resolver: yupResolver(UpdateSubscriptionSchema(licencePlateRegExp) as yup.ObjectSchema<Partial<Subscription>>),
        shouldUnregister: false,
        defaultValues: {
            licencePlate: subscription?.licencePlate,
            reference: subscription?.reference,
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields },
        reset,
        clearErrors,
    } = formMethods;

    const onSubmit: SubmitHandler<Partial<Subscription>> = async (data): Promise<void> => {
        ReactGA.event(ETrackingEvent.EDIT, {
            origin: ETrackingOrigin.SUBSCRIPTION_DETAILS,
            action: ETrackingAction.SUBMIT,
        });

        try {
            if (dirtyFields.licencePlate || dirtyFields.reference) {
                const patchData = dirtyFields.licencePlate
                    ? { licencePlate: data.licencePlate }
                    : { reference: data.reference };

                await patchSubscription.mutateAsync(patchData);
                addToast({ message: t('subscription.details.successMessage'), type: EToastType.SUCCESS });
                onSave();
            }
            setEditField(undefined);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.temporary) {
                    addToast({ message: t('general.errorToast'), type: EToastType.ERROR });
                } else {
                    addToast({ message: t('subscription.details.errorMessage'), type: EToastType.ERROR });
                }
            }
        }
    };

    const onCancel = (): void => {
        clearErrors();
        reset({
            licencePlate: subscription?.licencePlate,
            reference: subscription?.reference,
        });
        setEditField(undefined);
        ReactGA.event(ETrackingEvent.EDIT, {
            origin: ETrackingOrigin.SUBSCRIPTION_DETAILS,
            action: ETrackingAction.CANCEL,
        });
    };

    const handleEditClick = (field: string): void => {
        reset({
            licencePlate: subscription?.licencePlate,
            reference: subscription?.reference,
        });
        if (editField === field) {
            setEditField(undefined);
        } else {
            setEditField(field);
        }
    };

    return (
        <>
            {!subscription && isLoading && <LoadingIndicator displayBorder={false} displayText={false} />}
            {subscription && (
                <Container>
                    <ul>
                        <ListItem
                            title={t('general.status')}
                            value={
                                <StatusTag
                                    status={
                                        subscription.isActive
                                            ? ESubscriptionStatus.ACTIVE
                                            : ESubscriptionStatus.INACTIVE
                                    }
                                    infoButton={
                                        <SubscriptionStatusPopUp
                                            gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION_DETAILS}
                                        />
                                    }
                                />
                            }
                        />
                        {subscription.driver && (
                            <>
                                <ListItem
                                    title={t('general.name')}
                                    value={`${subscription.driver?.firstName} ${subscription.driver?.lastName}`}
                                />
                                <ListItem title={t('general.email')} value={subscription.driver?.email} />
                            </>
                        )}
                        {editAllowed ? (
                            <ClickableListItem
                                dataTestId="editButton_licencePlate"
                                onClick={() => {
                                    handleEditClick('licencePlate');
                                }}
                                title={t('general.licencePlate')}
                                text={subscription.licencePlate}
                                disabled={patchSubscription.isPending}
                            />
                        ) : (
                            <ListItem title={t('general.licencePlate')} value={subscription.licencePlate} />
                        )}
                        {editAllowed ? (
                            <ClickableListItem
                                dataTestId="editButton_reference"
                                onClick={() => {
                                    handleEditClick('reference');
                                }}
                                title={t('general.reference')}
                                text={subscription.reference}
                                disabled={patchSubscription.isPending}
                            />
                        ) : (
                            <ListItem title={t('general.reference')} value={subscription.reference} />
                        )}
                        <ListItem title={t('general.startDate')} value={formatDate(subscription.startDate)} />
                        {subscription.endDate && (
                            <ListItem title={t('general.endDate')} value={formatDate(subscription.endDate)} />
                        )}
                        <ListItem title={t('general.subscription')} value={subscription.productType} />
                        <ListItem
                            title={t('general.rfid')}
                            value={<Rfid>{subscription.rfids?.map((rfid) => <span key={rfid}>{rfid}</span>)}</Rfid>}
                        />
                        <ListItem title={t('general.organisation')} value={subscription.businessName} />
                    </ul>
                    {editAllowed && !!editField && (
                        <EditDetailsSection>
                            <Form formMethods={formMethods} onSubmit={handleSubmit(onSubmit)}>
                                {editField === 'licencePlate' && (
                                    <LicencePlateInput
                                        maxLength={licencePlateMaxLength}
                                        minLength={licencePlateMinLength}
                                        label={t('form.input.licencePlate.label')}
                                        required
                                        fieldError={errors.licencePlate}
                                        {...register('licencePlate', { required: true })}
                                    />
                                )}
                                {editField === 'reference' && (
                                    <TextInput
                                        maxLength={25}
                                        required
                                        label={t('form.input.reference.label')}
                                        fieldError={errors.reference}
                                        {...register('reference', { required: true })}
                                    />
                                )}
                                <ButtonContainer>
                                    <Button
                                        dataTestId="subscriptionDetailsPanelCancel"
                                        type={ButtonType.BUTTON}
                                        variant={ButtonVariant.SECONDARY}
                                        onClick={onCancel}
                                        disabled={patchSubscription.isPending}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        dataTestId="subscriptionDetailsPanelSave"
                                        type={ButtonType.SUBMIT}
                                        isLoading={patchSubscription.isPending}
                                    >
                                        {t('general.save')}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        </EditDetailsSection>
                    )}
                </Container>
            )}
        </>
    );
}

export default SubscriptionDetailsPanel;
