/* eslint-disable react/jsx-no-useless-fragment */
import { INITIAL_GLOBAL_STATE } from 'constants/initialData';
import useAuthentication from 'hooks/useAuthentication';
import { createStore, StateMachineProvider } from 'little-state-machine';
import React, { useEffect, useState } from 'react';

function StateMachineProviderWrapper({ children }: { children: React.ReactNode }): JSX.Element {
    const { getAccountContactId } = useAuthentication();

    const contactId = getAccountContactId();
    const [storeCreated, setStoreCreated] = useState<boolean>(false);

    useEffect(() => {
        const name = `mer_${contactId}`;
        createStore(
            {
                ...INITIAL_GLOBAL_STATE,
            },
            {
                name,
                middleWares: [],
                storageType: localStorage,
            },
        );
        setStoreCreated(true);
    }, [contactId, storeCreated]);

    if (storeCreated) {
        return <StateMachineProvider>{children}</StateMachineProvider>;
    }
    return <></>;
}

export default StateMachineProviderWrapper;
