import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import useApi from 'hooks/useApi';
import { FEATURE_LIST, NotificationsConnection } from 'types';
import { showFeature } from 'utils/featureFlag';

export type useNotificationsConnectionResponse = {
    notificationsConnection?: NotificationsConnection;
    isLoading: boolean;
    error?: ApiError;
    mutateNotificationsConnection: () => void;
};

const useNotificationsConnection = (): useNotificationsConnectionResponse => {
    const { get } = useApi<NotificationsConnection>();
    const url = `${GENERAL_BFF_PREFIX}/v1/notifications/connection-details`;

    const fetcher: () => Promise<NotificationsConnection> = () => get(url);
    const {
        data: notificationsConnection,
        isFetching: isLoading,
        error,
    } = useQuery<NotificationsConnection, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: showFeature(FEATURE_LIST.SIGNALR),
    });

    const queryClient = useQueryClient();
    const mutateNotificationsConnection = (): void => {
        queryClient.invalidateQueries({ queryKey: [url] });
    };

    return {
        notificationsConnection,
        mutateNotificationsConnection,
        isLoading,
        error: error ?? undefined,
    };
};

export default useNotificationsConnection;
