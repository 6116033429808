import styled from 'styled-components';
import { borderRadiusS, lightBlue, mediumGrey, shadowBlueSharp } from 'styles/variables';

const IconButton = styled.button`
    background: transparent;
    border: none;
    border-radius: ${borderRadiusS};
    cursor: pointer;
    line-height: 0;
    width: 2rem;
    height: 2rem;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover:not(:disabled) {
        background: ${lightBlue};
    }

    &:focus {
        box-shadow: ${shadowBlueSharp};
    }

    &:disabled {
        cursor: not-allowed;
        box-shadow: none;
        color: ${mediumGrey};
        svg {
            fill: ${mediumGrey};
        }
    }
`;

export default IconButton;
