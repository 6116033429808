import useBusinessId from './useBusinessId';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import useApi from 'hooks/useApi';
import { ActiveSubscription } from 'types/subscription';

export type UseActiveSubscriptionsResponse = {
    activeSubscriptions?: ActiveSubscription[];
    isLoading: boolean;
    error?: ApiError;
};

type ActiveSubscriptionsResult = {
    activeSubscriptions: ActiveSubscription[];
};

type UseActiveSubscriptionsProps = {
    businessId?: string;
};

const useActiveSubscriptions = ({ businessId }: UseActiveSubscriptionsProps): UseActiveSubscriptionsResponse => {
    const { get } = useApi<ActiveSubscriptionsResult>();
    const { activeParentBusinessId } = useBusinessId();

    const urlParams = new URLSearchParams([['businessId', businessId ?? '']]);
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${activeParentBusinessId}/active-subscriptions?${urlParams}`;
    const fetcher: () => Promise<ActiveSubscriptionsResult> = () => get(url);
    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<ActiveSubscriptionsResult, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: !!activeParentBusinessId,
    });

    return {
        activeSubscriptions: data?.activeSubscriptions,
        isLoading,
        error: error ?? undefined,
    };
};

export default useActiveSubscriptions;
