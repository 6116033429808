import CarIcon from 'assets/icons/car_side.svg?react';
import OrganisationIcon from 'assets/icons/company.svg?react';
import DeliveryIcon from 'assets/icons/delivery.svg?react';
import EmailIcon from 'assets/icons/envelope.svg?react';
import LocationIcon from 'assets/icons/location_pin.svg?react';
import PencilIcon from 'assets/icons/pencil.svg?react';
import ReferenceIcon from 'assets/icons/speech_bubble.svg?react';
import SubscriptionIcon from 'assets/icons/subscription_type.svg?react';
import BinIcon from 'assets/icons/trashcan.svg?react';
import UserIcon from 'assets/icons/user.svg?react';
import IconButton from 'components/clickables/IconButton';
import BulkImportInfoPopup from 'components/info/BulkImportInfoPopup';
import TextEllipsisComponent from 'components/info/TextEllipsisComponent';
import { ToastType } from 'components/info/ToastMessage';
import GridList from 'components/lists/GridList';
import RejectSubscriptionModal from 'components/subscriptions/RejectSubscriptionModal';
import {
    StyledGridListHeader,
    StyledGridListItem,
    TextContainer,
} from 'components/subscriptions/statistics/ChargingHistory';
import { useAddress } from 'hooks/useAddress';
import useGetBusiness from 'hooks/useGetBusiness';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    black,
    font,
    lightBlue,
    lighterGrey,
    lightRed,
    modalWidthMedium,
    primaryMerBlue,
    red,
    spaceL,
    spaceM,
    spaceS,
    spaceXs,
    spaceXxs,
} from 'styles/variables';
import { ButtonType, DropDownType, EToastType } from 'types';
import { Business, EBusinessFeature } from 'types/business';
import { IBulkSubscriptionType } from 'types/subscription';

const gridColumn = '80px repeat(4, 1fr)';

const SmallIconButton = styled(IconButton)`
    display: inline-block;
    width: ${spaceL};
    height: ${spaceL};
    margin-right: ${spaceXxs};
`;

const StickyColumn = styled.div`
    grid-row: 1 / span 2;
    background-color: inherit;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
`;

const EditBtn = styled(SmallIconButton)`
    background-color: ${lighterGrey};
    color: ${black};
    svg {
        width: 1rem;
        height: 1rem;
    }
    &:hover {
        color: ${primaryMerBlue};
        background-color: ${lightBlue};
    }
`;

const DeleteBtn = styled(SmallIconButton)`
    background-color: ${lighterGrey};
    svg {
        width: 1rem;
        height: 1rem;
    }
    &.invalid-row {
        &:hover {
            color: ${red};
            background-color: ${red}20;
        }
    }
    &:hover {
        color: ${red};
        background-color: ${lightRed};
    }
`;

const ListItem = styled.li`
    display: flex;
    margin: 0 ${spaceM} ${spaceS};
    font-size: ${font.size.m};
    gap: ${spaceXs};
`;

const ListItemText = styled.span`
    font-weight: ${font.weight.bold};
`;

type SubscriptionImportGridProps = {
    subscriptions: IBulkSubscriptionType[];
    open: (component: JSX.Element) => void;
    setModalWidth: (width: string) => void;
    removeBulkImportSubscriptionOrder: (row?: number) => void;
    businesses?: Business[];
    addToast: (v: Partial<ToastType>) => void;
    isLoading: boolean;
    openSubscriptionForm: (
        subscription: IBulkSubscriptionType,
        subscriptionOrganisationList: DropDownType[] | undefined,
        event: React.MouseEvent,
    ) => void;
    isSubmitting: boolean;
};

function SubscriptionImportGrid({
    subscriptions,
    open,
    setModalWidth,
    removeBulkImportSubscriptionOrder,
    businesses,
    addToast,
    isLoading,
    openSubscriptionForm,
    isSubmitting,
}: SubscriptionImportGridProps): JSX.Element {
    const { t } = useTranslation();
    const { getAddress } = useAddress();

    const { getBusinessesListByFeature } = useGetBusiness(businesses ?? []);

    const organisationList = useMemo((): DropDownType[] | undefined => {
        return businesses ? getBusinessesListByFeature([EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]) : undefined;
    }, [businesses, getBusinessesListByFeature]);

    const deleteOneOrder = useCallback(
        (row?: number): (() => void) => {
            return () => {
                removeBulkImportSubscriptionOrder(row);
                addToast({ message: t('subscription.importSubscriptions.deleteOne'), type: EToastType.SUCCESS });
            };
        },
        [removeBulkImportSubscriptionOrder, addToast, t],
    );

    const deleteOneOrderModal = useCallback(
        (order: IBulkSubscriptionType): (() => void) => {
            return () => {
                setModalWidth(modalWidthMedium);
                open(
                    <RejectSubscriptionModal
                        header={t('rejectSubscriptionModal.header.once')}
                        deleteOrder={deleteOneOrder(order.row)}
                    >
                        <ul>
                            <ListItem>
                                <ListItemText>{t('general.reference')}: </ListItemText>
                                {order.reference}
                            </ListItem>
                        </ul>
                    </RejectSubscriptionModal>,
                );
            };
        },
        [open, setModalWidth, t, deleteOneOrder],
    );

    return (
        <GridList
            gridColumns={gridColumn}
            dataTestId="importedTable"
            showEmptyListMessage={!isLoading && subscriptions.length === 0}
            isLoading={isLoading}
        >
            <StyledGridListHeader>
                {t('subscription.importSubscriptions.header')}
                <BulkImportInfoPopup />
            </StyledGridListHeader>
            {!isLoading && subscriptions.length > 0 && (
                <>
                    {subscriptions.map((subscription: IBulkSubscriptionType, index: number) => {
                        const organisationAddress = getAddress(
                            subscription.organisation?.name,
                            subscription.deliveryOption,
                            businesses,
                        )?.toString();
                        return (
                            <StyledGridListItem
                                key={subscription.row}
                                className={subscription.error ? 'invalid-row' : ''}
                            >
                                <StickyColumn>
                                    <EditBtn
                                        disabled={isSubmitting}
                                        type={ButtonType.BUTTON}
                                        data-testid={`editRow_${index}`}
                                        onClick={(event) => openSubscriptionForm(subscription, organisationList, event)}
                                    >
                                        <PencilIcon />
                                    </EditBtn>
                                    <DeleteBtn
                                        disabled={isSubmitting}
                                        type={ButtonType.BUTTON}
                                        data-testid={`deleteRow_${index}`}
                                        onClick={deleteOneOrderModal(subscription)}
                                        className={subscription.error ? 'invalid-row' : ''}
                                    >
                                        <BinIcon />
                                    </DeleteBtn>
                                </StickyColumn>
                                <TextContainer>
                                    <UserIcon />
                                    <TextEllipsisComponent
                                        tooltipText={`${subscription.contact?.firstName} ${subscription.contact?.lastName}`}
                                    >
                                        {`${subscription.contact?.firstName} ${subscription.contact?.lastName}`}
                                    </TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <CarIcon />
                                    <TextEllipsisComponent>{subscription.licencePlate}</TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <DeliveryIcon />
                                    <TextEllipsisComponent>
                                        {t(`deliveryOption.${subscription.deliveryOption}`)}
                                    </TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <OrganisationIcon />
                                    <TextEllipsisComponent tooltipText={subscription.organisation?.name}>
                                        {subscription.organisation?.name}
                                    </TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <EmailIcon />
                                    <TextEllipsisComponent tooltipText={subscription.contact?.email}>
                                        {subscription.contact?.email}
                                    </TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <ReferenceIcon />
                                    <TextEllipsisComponent tooltipText={subscription.reference}>
                                        {subscription.reference}
                                    </TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <LocationIcon />
                                    <TextEllipsisComponent tooltipText={organisationAddress}>
                                        {organisationAddress}
                                    </TextEllipsisComponent>
                                </TextContainer>
                                <TextContainer>
                                    <SubscriptionIcon />
                                    <TextEllipsisComponent>{subscription.type}</TextEllipsisComponent>
                                </TextContainer>
                            </StyledGridListItem>
                        );
                    })}
                </>
            )}
        </GridList>
    );
}

export default SubscriptionImportGrid;
