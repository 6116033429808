import StatusPill from 'components/info/StatusPill';
import styled from 'styled-components';
import { spaceXs } from 'styles/variables';
import { EPaymentStatus } from 'types/billing';
import { ESubscriptionOrderStatus, ESubscriptionStatus } from 'types/subscription';

const StatusTagWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${spaceXs};

    button.tippyButton {
        height: inherit;
    }
`;

type statusTagProp = {
    status: ESubscriptionStatus | ESubscriptionOrderStatus | EPaymentStatus;
    infoButton?: React.ReactNode;
};

function StatusTag({ status, infoButton }: statusTagProp): JSX.Element {
    return (
        <StatusTagWrapper>
            <StatusPill status={status} />
            {infoButton}
        </StatusTagWrapper>
    );
}

export default StatusTag;
