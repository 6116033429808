import { useAppInsights } from 'contexts/AppInsightsContext';
import ErrorPage from 'pages/ErrorPage';
import { useLocation } from 'react-router-dom';
import { EPageErrorType } from 'types';

function FirebaseErrorComponent(): JSX.Element {
    const { appInsights } = useAppInsights();
    const location = useLocation();

    if (appInsights) {
        appInsights.trackException({
            exception: new Error('Missing Firebase context'),
            severityLevel: 3,
            properties: {
                url: `${location.pathname}?${location.search}`,
                source: 'App',
            },
        });
    }

    return <ErrorPage type={EPageErrorType.TEMPORARY} displayBorder={false} />;
}

export default FirebaseErrorComponent;
