import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import useApi from 'hooks/useApi';
import { EProductType, ISubscriptionPlan } from 'types/product';

type SubscriptionPlansResult = { products: ISubscriptionPlan[] };

type useSubscriptionPlansProps = {
    businessId: string;
};
export type useSubscriptionPlansResult = {
    subscriptionPlans?: ISubscriptionPlan[];
    isLoading: boolean;
    error?: ApiError;
};
export function useSubscriptionPlans({ businessId }: useSubscriptionPlansProps): useSubscriptionPlansResult {
    const { get } = useApi<SubscriptionPlansResult>();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/products?productTypes=${EProductType.SUBSCRIPTION}`;
    const fetcher: () => Promise<SubscriptionPlansResult> = () => get(url);

    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<SubscriptionPlansResult, ApiError, SubscriptionPlansResult>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: !!businessId,
        staleTime: 3600000, // one hour
        gcTime: 3600000, // one hour
    });

    const subscriptionPlans = data?.products;

    return {
        subscriptionPlans,
        isLoading,
        error: error ?? undefined,
    };
}
