import { yupResolver } from '@hookform/resolvers/yup';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import Button from 'components/clickables/Button';
import LinkText from 'components/clickables/LinkText';
import Tabs, { Tab } from 'components/clickables/Tabs';
import DropdownInput from 'components/forms/DropdownInput';
import Form from 'components/forms/Form';
import TextInput from 'components/forms/TextInput';
import DeliveryOptionsInfo from 'components/info/DeliveryOptionsInfo';
import NotificationMessage, { EInfoType } from 'components/info/NotificationMessage';
import PopUp from 'components/info/PopUp';
import TooltipContent from 'components/info/TooltipContent';
import { EMAIL_REGEXP, MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from 'constants/general';
import { useToast } from 'contexts/Toast';
import useBUContent from 'hooks/useBUContent';
import useContact from 'hooks/useContact';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import useGetBusiness from 'hooks/useGetBusiness';
import useLocalState from 'hooks/useLocalState';
import { useSubscriptionPlans } from 'hooks/useSubscriptionPlans';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceL, spaceM, spaceS } from 'styles/variables';
import { ButtonType, ButtonVariant, DropDownType, EDeliveryOption, EToastType } from 'types';
import { Business } from 'types/business';
import { ISubscriptionPlan } from 'types/product';
import { ESubscriptionBulkError, ESubscriptionFormType, IBulkSubscriptionType } from 'types/subscription';
import { validateAddress } from 'utils/addressValidation';
import * as yup from 'yup';

const StyledTabs = styled(Tabs)`
    margin-bottom: ${spaceL};
    position: relative;
`;

const PopUpWrapper = styled.div`
    position: absolute;
    bottom: 12px;
    right: 0;
`;

const Container = styled.div`
    padding-bottom: ${spaceL};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${spaceS};
`;

const DropdownInputStyle = styled(DropdownInput)`
    margin-bottom: ${spaceS};
`;

const Wrapper = styled.div`
    display: flex;
    gap: ${spaceM};
`;

type SubscriptionFormProps = {
    formData: IBulkSubscriptionType;
    businesses?: Business[];
    organisationList?: DropDownType[];
    onClose: () => void;
};

const SubscriptionSchema = (
    licencePlateRegExp: RegExp,
    showDuplicateContractError: boolean,
    showDuplicateEmailError: boolean,
    allowedOrganisationIds?: string[],
    subscriptionPlans?: ISubscriptionPlan[],
): yup.ObjectSchema<IBulkSubscriptionType> =>
    yup.object().shape({
        error: yup.boolean().required(),
        id: yup.string(),
        row: yup.number(),
        type: yup
            .string()
            .test(
                'test if type is correct with the ones from BE',
                'form.input.subscription.required',
                (value, context) => {
                    if (!subscriptionPlans?.some((plan) => plan?.name === value || plan.offer?.name === value))
                        return context.createError({
                            path: 'type',
                            message: 'form.input.subscription.required',
                        });
                    return true;
                },
            )
            .required('form.input.subscription.required'),
        unknownContact: yup.boolean().required(),
        contact: yup.object().when('unknownContact', {
            is: false,
            then: () =>
                yup.object().shape({
                    email: yup
                        .string()
                        .test({
                            name: 'test that email is not already in use',
                            message: 'subscription.create.conflictError',
                            test: () => !showDuplicateContractError,
                        })
                        .test({
                            name: 'test if email address is used in a single imported entry',
                            message: 'subscription.create.duplicatedEmail',
                            test: () => !showDuplicateEmailError,
                        })
                        .max(MAX_LENGTH_EMAIL, 'form.input.email.lengthError')
                        .matches(EMAIL_REGEXP, { message: 'form.input.email.validation' })
                        .required('form.input.email.validation'),
                    firstName: yup
                        .string()
                        .required('form.input.firstName.required')
                        .max(MAX_LENGTH_NAME, 'form.input.firstName.lengthError'),
                    lastName: yup
                        .string()
                        .required('form.input.lastName.required')
                        .max(MAX_LENGTH_NAME, 'form.input.lastName.lengthError'),
                }),
        }),
        licencePlate: yup
            .string()
            .matches(licencePlateRegExp, { message: 'form.input.licencePlate.validation' })
            .required('form.input.licencePlate.validation'),
        reference: yup.string().required('form.input.reference.required').max(25, 'form.input.reference.lengthError'),
        organisation: yup.mixed<Business>().test('organisation-valid', '', (value, context) => {
            if (value?.name === '' || !allowedOrganisationIds?.some((e) => e === value?.id)) {
                return context.createError({
                    path: 'organisation.id',
                    message: 'form.input.organisation.required',
                });
            }
            return true;
        }),
        organisationId: yup.string(),
        deliveryOption: yup
            .mixed<EDeliveryOption>()
            .oneOf(Object.values(EDeliveryOption), 'form.input.deliveryOption.required')
            .required()
            .test(
                'select delivery if deliveryOption is Home address or organisation Address',
                'form.input.deliveryOption.required',
                (value, context) => {
                    const { unknownContact } = context.parent;
                    if (value === EDeliveryOption.HOME && unknownContact === true) {
                        return context.createError({
                            path: 'deliveryOption',
                            message: 'form.input.deliveryOption.required',
                        });
                    }
                    return true;
                },
            ),
        shipToPostalAddressId: yup.string(),
        errorType: yup.mixed<ESubscriptionBulkError[]>(),
    });

function SubscriptionImportForm({
    formData,
    businesses,
    organisationList,
    onClose,
}: SubscriptionFormProps): JSX.Element {
    const { t } = useTranslation();
    const { addToast } = useToast();
    const {
        actions: { updateBulkImportSubscriptionOrder },
    } = useLocalState();

    const { buContent } = useBUContent();

    const isEmailDuplicated = formData.errorType?.includes(ESubscriptionBulkError.DUPLICATE_EMAIL);
    const hasUserActiveContract = formData.errorType?.includes(ESubscriptionBulkError.USER_HAS_ACTIVE_DRIVER_CONTRACT);

    const allowedOrganisationIds = organisationList?.map((org) => org.value);

    const [selectedOrganisation, setSelectedOrganisation] = useState<Business | undefined>();
    const [showDuplicateContractError, setShowDuplicateContractError] = useState<boolean>(!!hasUserActiveContract);
    const [showDuplicateEmailError, setShowDuplicateEmailError] = useState<boolean>(!!isEmailDuplicated);

    const { subscriptionPlans, isLoading: isLoadingSubscriptionPlans } = useSubscriptionPlans({
        businessId: selectedOrganisation?.id ?? '',
    });

    const disabledSubscriptionPlan =
        !selectedOrganisation || isLoadingSubscriptionPlans || !subscriptionPlans || subscriptionPlans.length === 0;

    const tabsArray: Tab[] = useMemo(
        () =>
            Object.values(ESubscriptionFormType).map((subscriptionType) => ({
                text: t(`subscription.${subscriptionType}`),
                value: subscriptionType,
            })),
        [t],
    );

    const [selectedTab, setSelectedTab] = useState<Tab>(tabsArray[0]);
    const { licencePlateRegExp, licencePlateMaxLength, licencePlateMinLength } = useCountrySpecificContent();

    const resolver = useMemo(
        () =>
            yupResolver(
                SubscriptionSchema(
                    licencePlateRegExp,
                    showDuplicateContractError,
                    showDuplicateEmailError,
                    allowedOrganisationIds,
                    subscriptionPlans,
                ),
                {
                    abortEarly: false,
                },
            ),
        [
            licencePlateRegExp,
            allowedOrganisationIds,
            subscriptionPlans,
            showDuplicateContractError,
            showDuplicateEmailError,
        ],
    );

    const defaultTypeValue = subscriptionPlans?.find((plan) => {
        const organisation = organisationList?.find((org) => org.text === formData.organisation?.name);

        if (!organisation) {
            return undefined;
        }

        if (plan.offer?.name) {
            return plan.offer.name === formData.type;
        }
        return plan?.name === formData.type;
    });

    const defaultFormValues = useMemo(() => {
        return {
            ...formData,
            organisation: {
                id: organisationList?.find((org) => org.text === formData.organisation?.name)?.value ?? '',
                name: organisationList?.find((org) => org.text === formData.organisation?.name)?.text ?? '',
            },
            type: defaultTypeValue?.offer ? defaultTypeValue.offer.name : defaultTypeValue?.name,
        };
    }, [defaultTypeValue, formData, organisationList]);

    const formMethods = useForm<IBulkSubscriptionType>({
        mode: 'onChange',
        resolver,
        shouldUnregister: false,
        defaultValues: defaultFormValues,
    });

    const {
        reset,
        register,
        handleSubmit,
        trigger,
        setValue,
        formState: { errors, dirtyFields },
        watch,
        resetField,
    } = formMethods;

    useEffect(() => {
        setShowDuplicateContractError(!!hasUserActiveContract);
        setShowDuplicateEmailError(!!isEmailDuplicated);
        trigger();
    }, [defaultFormValues, hasUserActiveContract, isEmailDuplicated, reset, trigger]);

    useEffect(() => {
        if (!isLoadingSubscriptionPlans && defaultTypeValue?.offer) {
            resetField('type', { defaultValue: defaultTypeValue.offer.name });
        } else if (!isLoadingSubscriptionPlans && defaultTypeValue?.name) {
            resetField('type', { defaultValue: defaultTypeValue.name });
        }
    }, [defaultTypeValue?.offer, defaultTypeValue?.name, isLoadingSubscriptionPlans, resetField]);

    const { getBusiness } = useGetBusiness(businesses ?? []);

    const organisationId = watch('organisation.id');

    const organisation = useMemo(
        () => (organisationId ? getBusiness(organisationId) : undefined),
        [getBusiness, organisationId],
    );

    const validAddress = validateAddress(selectedOrganisation?.address);

    useEffect(() => {
        if (organisationId && businesses && organisation) {
            setSelectedOrganisation(organisation);
            if (selectedTab.value === ESubscriptionFormType.UNPERSONALISED) {
                if (validAddress) {
                    setValue('deliveryOption', EDeliveryOption.MAIN, { shouldDirty: true });
                } else {
                    setValue('deliveryOption', '');
                }
            } else if (validAddress) {
                resetField('deliveryOption', { keepError: true });
            } else if (formData.deliveryOption !== EDeliveryOption.HOME) {
                setValue('deliveryOption', '');
            }
        } else {
            setSelectedOrganisation(undefined);
            resetField('deliveryOption', { keepError: true });
        }
    }, [
        businesses,
        organisation,
        organisationId,
        resetField,
        selectedTab.value,
        setValue,
        validAddress,
        formData.deliveryOption,
    ]);

    useEffect(() => {
        if (formData.unknownContact) {
            setSelectedTab(tabsArray[1]);
        } else {
            setSelectedTab(tabsArray[0]);
        }
    }, [formData.unknownContact, tabsArray]);

    const [email, setEmail] = useState(watch('contact.email'));
    const { contact, error: contactError } = useContact(organisationId, email);

    useEffect(() => {
        if (formData.contact?.email !== email) {
            setShowDuplicateContractError(false);
            setShowDuplicateEmailError(false);
        }
    }, [email, formData.contact?.email]);

    useEffect(() => {
        if (subscriptionPlans) {
            trigger(['type']);
        }
        if (watch('contact.email')) {
            trigger(['contact.email']);
        }
        if (watch('contact.firstName')) {
            trigger(['contact.firstName']);
        }
        if (watch('contact.lastName')) {
            trigger(['contact.lastName']);
        }
        if (contact || watch('contact.email') === '') {
            trigger(['contact.email', 'contact.firstName', 'contact.lastName']);
        }
        trigger(['licencePlate', 'reference', 'organisation', 'deliveryOption']);
    }, [
        trigger,
        isLoadingSubscriptionPlans,
        subscriptionPlans,
        contact,
        watch,
        showDuplicateContractError,
        showDuplicateEmailError,
    ]);

    const changeFormType = (): void => {
        setValue('unknownContact', selectedTab.value === ESubscriptionFormType.PERSONALISED, { shouldDirty: true });
    };

    const onSubmit = (dta: IBulkSubscriptionType): void => {
        // remove personal details and no errors should be displayed anymore
        if (Object.keys(dirtyFields).length > 0) {
            updateBulkImportSubscriptionOrder({
                ...dta,
                error: false,
                errorType: undefined,
                contact:
                    selectedTab.value === ESubscriptionFormType.UNPERSONALISED
                        ? { firstName: '', lastName: '', email: '' }
                        : dta.contact,
                shipToPostalAddressId:
                    dta.deliveryOption === EDeliveryOption.MAIN ? selectedOrganisation?.address?.id : undefined,
            });

            addToast({ message: t('subscription.details.successMessage'), type: EToastType.SUCCESS });
        }
    };

    const onOrganisationChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        setValue('organisation.name', e.target.options[e.currentTarget.selectedIndex].text, { shouldDirty: true });
        setValue('organisation.id', e.currentTarget.value, { shouldDirty: true });
    };

    const showContactError = contactError && contactError.status !== 404;

    const showEmailErrorInfo = showContactError;

    const onBlur = (e: { target: { value: string | undefined } }): void => {
        setValue('contact.email', e.target.value, { shouldValidate: true });
        if (e.target.value && EMAIL_REGEXP.test(e.target.value)) {
            setEmail(e.target.value);
        }
    };

    const firstNameValue = watch('contact.firstName');
    const lastNameValue = watch('contact.lastName');
    const nameMask = '*****';

    useEffect(() => {
        if (contact) {
            const firstName = contact.firstName ?? nameMask;
            const lastName = contact.lastName ?? nameMask;
            setValue('contact.firstName', firstName, { shouldValidate: true });
            setValue('contact.lastName', lastName, { shouldValidate: true });
        } else if (
            defaultFormValues.contact?.firstName === nameMask ||
            defaultFormValues.contact?.lastName === nameMask
        ) {
            setValue('contact.firstName', '', { shouldValidate: false });
            setValue('contact.lastName', '', { shouldValidate: false });
        } else {
            resetField('contact.firstName', { keepError: false });
            resetField('contact.lastName', { keepError: false });
        }
    }, [contact, setValue, resetField, defaultFormValues.contact]);

    const deliveryOptions = [
        {
            text: t(`deliveryOption.${EDeliveryOption.HOME}`),
            value: EDeliveryOption.HOME,
        },
    ];

    if (validAddress) {
        deliveryOptions.unshift({
            text: t(`deliveryOption.${EDeliveryOption.MAIN}`),
            value: EDeliveryOption.MAIN,
        });
    }

    const emailErrorMessage = (): React.ReactNode => {
        if (showDuplicateContractError) {
            return <Trans i18nKey="subscription.create.conflictError" />;
        }
        return (
            <Trans
                i18nKey="subscription.create.emailInfo"
                values={{
                    mailAddress: buContent.customerService.email,
                    phoneNumber: buContent.customerService.phoneNumer,
                }}
                components={{
                    mailto: <LinkText to={`mailto:${buContent.customerService.email}`} />,
                }}
            />
        );
    };

    return (
        <Container data-testid="subscriptionEdit">
            <StyledTabs
                tabs={tabsArray}
                activeTab={selectedTab}
                onTabClick={setSelectedTab}
                size="normal"
                onClick={changeFormType}
            >
                <PopUpWrapper>
                    <PopUp
                        dataTestId="subscriptionTypeInfo"
                        content={
                            <TooltipContent
                                headline={t('subscription.create.subscriptionTypesInfo.title')}
                                text={t('subscription.create.subscriptionTypesInfo.description')}
                                icon={<QuestionMarkTooltipIcon />}
                            />
                        }
                    />
                </PopUpWrapper>
            </StyledTabs>
            <Form formMethods={formMethods} onSubmit={handleSubmit(onSubmit)}>
                <DropdownInputStyle
                    name="organisation.id"
                    dataTestId="subscriptionFormOrganisation"
                    label={t('form.input.organisation.label')}
                    options={organisationList?.map((org) => ({
                        value: org.value,
                        text: org.text,
                    }))}
                    fieldError={errors.organisation?.id}
                    required
                    onChange={onOrganisationChange}
                    value={watch('organisation.id')}
                    customPlaceholderOption="form.input.organisation.placeHolder"
                />
                <DropdownInputStyle
                    dataTestId="createSubscriptionFormPlan"
                    label={t('form.input.subscription.label')}
                    options={subscriptionPlans?.map((plan) => ({
                        value: plan.offer?.name || plan.name,
                        text: plan.offer?.name || plan.name,
                    }))}
                    fieldError={errors.type}
                    required
                    customPlaceholderOption="form.input.subscription.placeHolder"
                    infoMessage={
                        !!selectedOrganisation && !isLoadingSubscriptionPlans && disabledSubscriptionPlan ? (
                            <NotificationMessage
                                type={EInfoType.ERROR}
                                dataTestId="createSubscriptionFormPlanDisabled"
                                showTitle={false}
                                message={
                                    <Trans
                                        i18nKey="subscription.create.subscriptionPlanErrorMessage"
                                        values={{
                                            mailAddress: buContent.customerService.email,
                                            phoneNumber: buContent.customerService.phoneNumer,
                                        }}
                                        components={{
                                            mailto: <LinkText to={`mailto:${buContent.customerService.email}`} />,
                                        }}
                                    />
                                }
                            />
                        ) : null
                    }
                    value={watch('type')}
                    {...register('type', { required: true })}
                    disabled={disabledSubscriptionPlan}
                    isLoading={isLoadingSubscriptionPlans}
                />
                {selectedTab.value === ESubscriptionFormType.PERSONALISED && (
                    <>
                        <TextInput
                            dataTestId="subscriptionFormEmail"
                            label={t('form.input.email.label')}
                            fieldError={errors.contact?.email}
                            required
                            infoMessage={
                                showEmailErrorInfo && (
                                    <NotificationMessage
                                        type={EInfoType.ERROR}
                                        dataTestId="subscriptionFormEmailInfoMessage"
                                        showTitle={false}
                                        message={emailErrorMessage()}
                                    />
                                )
                            }
                            {...register('contact.email', { required: true })}
                            onBlur={onBlur}
                        />
                        <Wrapper>
                            <TextInput
                                dataTestId="subscriptionFormFirstName"
                                label={t('form.input.firstName.label')}
                                maxLength={MAX_LENGTH_NAME}
                                required
                                fieldError={errors.contact?.firstName}
                                {...register('contact.firstName', { required: true })}
                                disabled={!!contact?.firstName || firstNameValue === nameMask}
                            />
                            <TextInput
                                dataTestId="subscriptionFormLastName"
                                label={t('form.input.lastName.label')}
                                maxLength={MAX_LENGTH_NAME}
                                required
                                fieldError={errors.contact?.lastName}
                                {...register('contact.lastName', { required: true })}
                                disabled={!!contact?.lastName || lastNameValue === nameMask}
                            />
                        </Wrapper>
                    </>
                )}
                <TextInput
                    width="8.75rem"
                    dataTestId="subscriptionFormLicencePlate"
                    displayErrorTextOnOneLine
                    maxLength={licencePlateMaxLength}
                    minLength={licencePlateMinLength}
                    label={t('form.input.licencePlate.label')}
                    required
                    fieldError={errors.licencePlate}
                    {...register('licencePlate', { required: true })}
                />
                <TextInput
                    dataTestId="subscriptionFormReference"
                    maxLength={25}
                    required
                    label={t('form.input.reference.label')}
                    fieldError={errors.reference}
                    {...register('reference', { required: true })}
                />
                <DropdownInputStyle
                    dataTestId="subscriptionFormDeliveryOption"
                    label={t('form.input.deliveryOption.label')}
                    infoButton={<DeliveryOptionsInfo />}
                    options={deliveryOptions}
                    fieldError={errors.deliveryOption}
                    customPlaceholderOption="form.input.deliveryOption.placeHolder"
                    required
                    disabled={!organisationId || selectedTab.value === ESubscriptionFormType.UNPERSONALISED}
                    value={watch('deliveryOption')}
                    {...register('deliveryOption', { required: true })}
                    infoMessage={
                        !validAddress && organisationId ? (
                            <NotificationMessage
                                dataTestId="createSubscriptionFormDeliveryOptionInfo"
                                showTitle={false}
                                message={
                                    <Trans
                                        i18nKey="subscription.create.deliveryOptionInfo"
                                        values={{
                                            mailAddress: buContent.customerService.email,
                                            phoneNumber: buContent.customerService.phoneNumer,
                                        }}
                                        components={{
                                            mailto: <LinkText to={`mailto:${buContent.customerService.email}`} />,
                                        }}
                                    />
                                }
                            />
                        ) : null
                    }
                />

                <ButtonContainer>
                    <Button
                        dataTestId="subscriptionFormCancel"
                        variant={ButtonVariant.SECONDARY}
                        onClick={() => onClose()}
                    >
                        {t('form.button.cancel')}
                    </Button>
                    <Button dataTestId="subscriptionFormSave" type={ButtonType.SUBMIT} variant={ButtonVariant.PRIMARY}>
                        {t('form.button.saveChanges')}
                    </Button>
                </ButtonContainer>
            </Form>
        </Container>
    );
}

export default SubscriptionImportForm;
