import Button from 'components/clickables/Button';
import Tabs, { Tab } from 'components/clickables/Tabs';
import Page from 'components/layout/Page';
import ImportFileModal from 'components/subscriptions/ImportFileModal';
import SubscriptionList from 'components/subscriptions/SubscriptionList';
import SubscriptionOrderList from 'components/subscriptions/SubscriptionOrderList';
import { PATH } from 'constants/paths';
import { useModal } from 'contexts/Modal';
import useAuthorization from 'hooks/useAuthorization';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { modalWidthMedium, spaceM, spaceS } from 'styles/variables';
import { ButtonVariant } from 'types';
import { EBusinessFeature } from 'types/business';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin } from 'types/tracking';

const StyledTabs = styled(Tabs)`
    margin-bottom: ${spaceM};
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: ${spaceS};
    @media screen and (max-width: 930px) {
        button {
            width: 100%;
            max-width: 15rem;
        }
    }
`;

function SubscriptionOverviewPage(): JSX.Element {
    const { t } = useTranslation();
    const { open, setModalWidth } = useModal();

    const {
        checkAuthorizationFeature,
        error: errorAuthorization,
        isLoading: isLoadingAuthorization,
    } = useAuthorization();
    const editAllowed = checkAuthorizationFeature(EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT);

    const navigate = useNavigate();

    const handleOnAddSubscription = (): void => {
        navigate(PATH.SUBSCRIPTION_CREATE);
    };

    const openBrowseModal = (): void => {
        if (open) {
            ReactGA.event(ETrackingEvent.IMPORT_SUBSCRIPTIONS, {
                origin: ETrackingOrigin.SUBSCRIPTION,
                action: ETrackingAction.OPEN_MODAL,
            });
            setModalWidth(modalWidthMedium);
            open(<ImportFileModal />);
        }
    };

    const apiErrors = [];
    if (!isLoadingAuthorization && errorAuthorization) {
        apiErrors.push(errorAuthorization);
    }

    const tabsArray: Tab[] = useMemo(() => {
        return [
            {
                text: `${t('subscription.overview.tabs.subscription')}`,
                value: 'subscription',
            },
            {
                text: `${t('subscription.overview.tabs.order')}`,
                value: 'order',
            },
        ];
    }, [t]);

    const [searchParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState<Tab>(
        tabsArray.find((tab) => tab.value === searchParams.get('tab')) ?? tabsArray[0],
    );
    useEffect(() => {
        if (searchParams.get('tab') === null) {
            setSelectedTab(tabsArray[0]);
        }
    }, [searchParams, tabsArray]);

    return (
        <Page
            title={t('subscription.overview.title')}
            errors={apiErrors}
            headerContent={
                editAllowed && (
                    <ButtonContainer>
                        <Button
                            onClick={handleOnAddSubscription}
                            variant={ButtonVariant.SECONDARY}
                            dataTestId="addNewSubscriptionButton"
                        >
                            {t('subscription.overview.newSubscription')}
                        </Button>
                        <Button
                            variant={ButtonVariant.SECONDARY}
                            onClick={() => {
                                openBrowseModal();
                            }}
                            dataTestId="importFileButton"
                        >
                            {t('subscription.importFile')}
                        </Button>
                    </ButtonContainer>
                )
            }
        >
            <StyledTabs tabs={tabsArray} activeTab={selectedTab} onTabClick={setSelectedTab} size="normal" />
            {selectedTab.value === 'subscription' ? <SubscriptionList /> : <SubscriptionOrderList />}
        </Page>
    );
}

export default SubscriptionOverviewPage;
