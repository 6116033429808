import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import React, { ReactNode, useContext, useMemo } from 'react';

type Props = {
    children: ReactNode;
};

interface AppInsightsContextType {
    appInsights: ApplicationInsights | null;
}

const AppInsightsContext = React.createContext<AppInsightsContextType>({
    appInsights: null,
});

export function AppInsightsProvider(props: Props): JSX.Element {
    const appInsights = useMemo(() => {
        const reactPlugin = new ReactPlugin();
        const browserHistory = createBrowserHistory();
        const ai = new ApplicationInsights({
            config: {
                connectionString: import.meta.env.VITE_APP_INSIGHTS_CONNECTION_STRING,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                },
            },
        });
        ai.loadAppInsights();
        ai.addTelemetryInitializer((envelope: ITelemetryItem) => {
            if (envelope.data && envelope.baseType === 'ExceptionData') {
                const exceptionData = envelope.baseData;

                if (exceptionData && exceptionData.exceptions) {
                    exceptionData.exceptions = exceptionData.exceptions.map((exception: object) => {
                        return {
                            ...exception,
                            hasFullStack: false,
                            stack: '',
                            parsedStack: [],
                        };
                    });
                }
            }
        });
        return { appInsights: ai };
    }, []);

    return <AppInsightsContext.Provider value={appInsights} {...props} />;
}

export const useAppInsights = (): AppInsightsContextType => useContext(AppInsightsContext);
