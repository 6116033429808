import Button from './Button';
import Arrow from 'assets/icons/chevron.svg?react';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusS, font, lightBlue, menuBoxShadow, spaceS, spaceXs, spaceXxxs, white } from 'styles/variables';
import { ButtonVariant, EExportFileType } from 'types';

const Container = styled.div`
    position: relative;
`;

const DropdownMenu = styled.ul`
    box-shadow: ${menuBoxShadow};
    min-width: 9rem;
    margin-top: ${spaceXxxs};
    position: absolute;
    background-color: ${white};
    border-radius: ${borderRadiusS};

    li {
        display: flex;
        align-items: center;
        min-height: 2.25rem;

        &:first-child {
            margin-top: ${spaceXs};
        }

        &:last-child {
            margin-bottom: ${spaceXs};
        }

        &:hover {
            background-color: ${lightBlue};
        }

        button {
            height: 2.25rem;
            text-align: left;
            width: 100%;
            background: none;
            color: inherit;
            border: none;
            font: inherit;
            font-weight: ${font.weight.semiBold};
            padding-left: ${spaceS};
        }
    }
`;
const StyledButton = styled(Button)`
    display: flex;
`;

type ExportButtonProps = {
    getExport: () => void;
    className?: string;
    showMessage?: boolean;
    isLoading?: boolean;
    setFileType: Dispatch<SetStateAction<EExportFileType>>;
};

function ExportButton({ setFileType, getExport, className, showMessage, isLoading }: ExportButtonProps): JSX.Element {
    const { t } = useTranslation();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const domRef = useRef<HTMLDivElement>();

    const generateExport = (type: EExportFileType): void => {
        setFileType(type);
        getExport();
        setDropDownOpen(false);
    };

    useEffect(() => {
        function handler(e: MouseEvent): void {
            if (!domRef.current?.contains(e.target as HTMLDivElement)) {
                setDropDownOpen(false);
            }
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    return (
        <Container ref={domRef as unknown as MutableRefObject<HTMLDivElement>}>
            <StyledButton
                dataTestId="exportButton"
                isLoading={isLoading}
                onClick={() => setDropDownOpen(!dropDownOpen)}
                className={className}
                variant={ButtonVariant.SECONDARY}
                disabled={showMessage}
                aria-expanded={dropDownOpen}
            >
                {t('general.export.title')}
                <Arrow className="arrowDown" />
            </StyledButton>
            {dropDownOpen && (
                <DropdownMenu>
                    <li>
                        <button
                            data-testid="pdfExport"
                            type="button"
                            onClick={() => generateExport(EExportFileType.PDF)}
                        >
                            {t('general.export.pdf')}
                        </button>
                    </li>
                    <li>
                        <button
                            data-testid="excelExport"
                            type="button"
                            onClick={() => generateExport(EExportFileType.EXCEL)}
                        >
                            {t('general.export.excel')}
                        </button>
                    </li>
                </DropdownMenu>
            )}
        </Container>
    );
}

export default ExportButton;
