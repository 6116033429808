import useBusinessId from './useBusinessId';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import useApi from 'hooks/useApi';

export type UseCo2Response = {
    co2Data?: UseCo2Result;
    isLoading: boolean;
    error?: ApiError;
};
type UseCo2Props = {
    businessId?: string;
};
export type UseCo2Result = {
    homeCO2Saving: number;
    publicCO2Saving: number;
    internalCO2Saving: number;
    savedTrees: number;
};
const useCo2 = ({ businessId }: UseCo2Props): UseCo2Response => {
    const paramsArray: string[][] = [];
    const { activeParentBusinessId } = useBusinessId();

    if (businessId) {
        paramsArray.push(['businessId', businessId]);
    }

    const { get } = useApi<UseCo2Result>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${activeParentBusinessId}/billing-transactions/co2-savings?${urlParams}`;
    const fetcher: () => Promise<UseCo2Result> = () => get(url);
    const {
        data: co2Data,
        isFetching: isLoading,
        error,
    } = useQuery<UseCo2Result, ApiError>({
        queryKey: [`${url}?${businessId}`],
        queryFn: fetcher,
        enabled: !!activeParentBusinessId,
    });

    return {
        co2Data,
        isLoading,
        error: error ?? undefined,
    };
};
export default useCo2;
