import { ReactNode } from 'react';
import styled from 'styled-components';
import { borderRadiusM, font, panelBackgroundColorBlue, spaceM, spaceS, spaceXs } from 'styles/variables';

const Container = styled.article`
    position: relative;
    display: flex;
    gap: ${spaceXs};
    padding: ${spaceM};
    background: ${panelBackgroundColorBlue};
    border-radius: ${borderRadiusM};
`;
const Wrapper = styled.div`
    display: flex;
    margin-top: ${spaceS};
    gap: ${spaceS};
`;

const Title = styled.h2`
    font-size: ${font.size.l};
    font-weight: ${font.weight.semiBold};
`;
const Value = styled.p`
    margin: 0;
    line-height: ${font.lineHeight.xxl};
    font-size: ${font.size.xl};
    font-family: ${font.bold};
    font-weight: ${font.weight.bold};
`;

const Content = styled.div`
    flex: 1;
`;

const InfoButton = styled.span`
    position: absolute;
    right: ${spaceXs};
    top: ${spaceXs};
`;

type SmallKpiContainerProps = {
    imageContent: ReactNode;
    title: string;
    value: number | string;
    unit: string;
    tooltip?: React.ReactNode;
    dataTestId: string;
};
function KpiContainerSmall({
    imageContent,
    title,
    value,
    unit,
    dataTestId,
    tooltip,
}: SmallKpiContainerProps): JSX.Element {
    return (
        <Container data-testid={dataTestId}>
            {!!tooltip && <InfoButton>{tooltip}</InfoButton>}
            <Content>
                <Title>{title}</Title>
                <Wrapper>
                    {imageContent}
                    <Value>
                        {value} <span>{unit}</span>
                    </Value>
                </Wrapper>
            </Content>
        </Container>
    );
}

export default KpiContainerSmall;
