import styled from 'styled-components';
import { darkGrey, font, secondaryDarkTurquoise } from 'styles/variables';

const Text = styled.p<{ color?: string }>`
    width: 8.75rem;
    height: 8.75rem;
    border-radius: 100%;
    border: ${({ color }) => `16px solid ${color}`};
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${font.size.m};
    font-weight: ${font.weight.semiBold};

    span {
        color: ${darkGrey};
        font-size: ${font.size.xs};
        font-weight: ${font.weight.semiBold};
    }
`;

type PieChartNoDataProps = {
    unit?: string | JSX.Element;
    color?: string;
};

function PieChartNoData({ unit, color = secondaryDarkTurquoise }: PieChartNoDataProps): JSX.Element {
    return (
        <Text color={color}>
            0<span>{unit}</span>
        </Text>
    );
}
export default PieChartNoData;
