import PieChartNoData from './PieChartNoData';
import { useNumber } from 'hooks/useNumber';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart as RechartsPieChart, ResponsiveContainer, Sector } from 'recharts';
import { PieSectorDataItem } from 'recharts/types/polar/Pie';
import { ActiveShape } from 'recharts/types/util/types';
import styled from 'styled-components';
import { black, blue, darkGrey, font } from 'styles/variables';

const StyledResponsiveContainer = styled(ResponsiveContainer)<{ color: string }>`
    .segment-name {
        fill: ${black};
        font-size: ${font.size.xs};
        font-weight: ${font.weight.semiBold};
    }
    .segment-value {
        fill: ${black};
        font-size: ${font.size.m};
        font-weight: ${font.weight.semiBold};
    }
    .segment-unit {
        fill: ${darkGrey};
        font-size: ${font.size.xs};
        font-weight: ${font.weight.semiBold};
    }

    g:focus {
        outline: none;
    }
    .active-segment {
        filter: brightness(0.6);
    }
`;

type ShapeProps = {
    cx: number;
    cy: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    payload: PieChartData;
    value: string | number;
};
const renderActiveShape = (
    props: ShapeProps,
    unit: string | JSX.Element,
    color: string,
    formatNumber: (value: number) => string,
): JSX.Element => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, value } = props;

    return (
        <g>
            <text x="50%" y={20} height="100%" textAnchor="middle" className="segment-name">
                {payload.name}
            </text>
            <text x={cx} y={unit ? cy - 8 : cy + 4} textAnchor="middle" className="segment-value">
                {formatNumber(Number(value))}
            </text>
            <text x={cx} y={cy + 12} textAnchor="middle" className="segment-unit">
                {unit}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={color}
                className="active-segment"
            />
        </g>
    );
};

export type PieChartData = {
    name: string;
    value: string | number;
};

type PieChartProps = {
    chartData: PieChartData[];
    unit?: string | JSX.Element;
    color?: string;
    className?: string;
    noData?: boolean;
};

function PieChart({ chartData, unit = '', color = blue, className = '', noData }: PieChartProps): JSX.Element {
    const sortedData: PieChartData[] = [...chartData].sort((a, b) => Number(a.value) - Number(b.value));
    const [activeIndex, setActiveIndex] = useState(0);
    const { formatNumber } = useNumber();

    const nonNullData = sortedData.filter((item) => item.value !== 0);

    useEffect(() => {
        if (activeIndex > sortedData.length) {
            setActiveIndex(0);
        }
    }, [setActiveIndex, activeIndex, sortedData.length]);

    if (noData) {
        return <PieChartNoData unit={unit} color={color} />;
    }

    const onPieEnter = (_: unknown, index: number): void => {
        setActiveIndex(index);
    };

    return (
        <StyledResponsiveContainer width="100%" height={190} data-testid="pieChart" className={className} color={color}>
            <RechartsPieChart width={200} height={200}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={(props: ActiveShape<PieSectorDataItem>) =>
                        renderActiveShape(props as ShapeProps, unit, color, formatNumber)
                    }
                    data={nonNullData}
                    cx="50%"
                    cy="58%"
                    innerRadius={53}
                    outerRadius={70}
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                    paddingAngle={1}
                    startAngle={-270}
                    endAngle={-630}
                >
                    {chartData?.map((entry) => <Cell key={`cell-${entry.name}`} fill={color} />)}
                </Pie>
            </RechartsPieChart>
        </StyledResponsiveContainer>
    );
}

export default PieChart;
