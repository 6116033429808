import ModalWrapper from 'components/info/ModalWrapper';
import LittleStateMachineProvider from 'contexts/StateMachineProviderWrapper';
import { Outlet } from 'react-router-dom';

function RouteWrapper(): JSX.Element {
    return (
        <LittleStateMachineProvider>
            <Outlet />
            <ModalWrapper />
        </LittleStateMachineProvider>
    );
}

export default RouteWrapper;
