import ErrorIcon from 'assets/icons/exclamation_circle.svg?react';
import InfoIcon from 'assets/icons/info_circle.svg?react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusS, font, lighterBlue, lightPink, primaryMerBlue, red, spaceXs, spaceXxs } from 'styles/variables';

const Container = styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
    padding: ${spaceXs};
    gap: ${spaceXs};

    font-size: ${font.size.s};

    border-radius: ${borderRadiusS};
    &.info {
        background: ${lighterBlue};
        svg {
            color: ${primaryMerBlue};
        }
    }
    &.error {
        background: ${lightPink};
        svg {
            color: ${red};
        }
    }
`;

const Message = styled.p`
    margin: 0;
    line-height: ${font.lineHeight.m};
    align-self: center;
`;
const MessageType = styled.span`
    display: block;
    font-weight: ${font.weight.bold};
    margin-bottom: ${spaceXxs};
    line-height: ${font.lineHeight.l};
`;

export enum EInfoType {
    INFO = 'info',
    ERROR = 'error',
}

type NotificationMessageProps = {
    message?: string | React.ReactNode;
    type?: EInfoType;
    showTitle?: boolean;
    dataTestId?: string;
};

function NotificationMessage({
    message,
    type = EInfoType.INFO,
    showTitle = true,
    dataTestId,
}: NotificationMessageProps): JSX.Element {
    const { t } = useTranslation();
    const infoType = type === EInfoType.INFO;

    return (
        <Container className={`${type}`} data-testid={dataTestId ?? 'notificationMessage'}>
            {infoType ? <InfoIcon /> : <ErrorIcon />}
            <Message>
                {showTitle && (
                    <MessageType>
                        {infoType ? t('widget.notificationMessage.info') : t('widget.notificationMessage.error')}
                    </MessageType>
                )}
                {message}
            </Message>
        </Container>
    );
}

export default memo(NotificationMessage);
