import BarChart from 'components/charts/BarChart';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import EmptyListMessage, { EMessageSize } from 'components/info/EmptyListMessage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusM, colorHome, colorInternal, colorPublic, panelBackgroundColorBlue } from 'styles/variables';
import { ChartEntries } from 'types/subscription';
import { generateBarChartData } from 'utils/subscription/chart';

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 3;
    min-height: 19rem;
    max-height: 19rem;

    &.empty {
        background: ${panelBackgroundColorBlue};
        border-radius: ${borderRadiusM};
    }
`;

type ConsumedEnergyChartProps = {
    data?: ChartEntries[];
    timeFrame: string;
    isLoading: boolean;
};

function ConsumedEnergyChart({ data, timeFrame, isLoading }: ConsumedEnergyChartProps): JSX.Element {
    const { t } = useTranslation();
    const barChartData = data ? generateBarChartData(data, timeFrame) : [];

    const barItemConfigData = [
        { dataKey: 'public', color: colorPublic, legend: t('usageType.PUBLIC') },
        { dataKey: 'internal', color: colorInternal, legend: t('usageType.INTERNAL') },
        { dataKey: 'home', color: colorHome, legend: t('usageType.HOME') },
    ];

    const noData = !isLoading && !barChartData.length;

    return (
        <Container className={noData ? 'empty' : ''}>
            {isLoading ? <LoadingIndicator displayText={false} height="100%" displayBorder={false} /> : null}
            {!isLoading && barChartData.length ? (
                <BarChart xAxisLabel="kWh" barItemConfig={barItemConfigData} chartData={barChartData} />
            ) : null}
            {noData ? <EmptyListMessage title={t('subscription.statistics.noData')} size={EMessageSize.SMALL} /> : null}
        </Container>
    );
}

export default ConsumedEnergyChart;
