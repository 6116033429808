import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import { darkerBlue, font } from 'styles/variables';
import { followCursor } from 'tippy.js';

const StyledTippy = styled(Tippy)`
    background: ${darkerBlue};
    font-family: ${font.body};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.semiBold};
`;
const Container = styled.div`
    cursor: pointer;
    display: inherit;
`;

type TooltipProps = {
    text: string;
    children: React.ReactNode;
};

function Tooltip({ text, children }: TooltipProps): JSX.Element {
    return (
        <StyledTippy
            content={text}
            arrow={false}
            followCursor
            plugins={[followCursor]}
            placement="right-end"
            delay={100}
            maxWidth="max-content"
        >
            <Container>{children}</Container>
        </StyledTippy>
    );
}

export default Tooltip;
