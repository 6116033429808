import useBroadcast from './useBroadcast';
import {
    AccountInfo,
    AuthError,
    EndSessionRequest,
    PublicClientApplication,
    RedirectRequest,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useAppInsights } from 'contexts/AppInsightsContext';
import { getBaseUrl, getBusinessUnitCode } from 'utils/url';

export const signupRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY!,
    prompt: 'login',
    extraQueryParameters: {
        entry_point: 'signup',
    },
};

export const regularRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY!,
};

export const changeEmailRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY_CHANGEEMAIL!,
};

export const pca = new PublicClientApplication({
    auth: {
        clientId: import.meta.env.VITE_APP_AZUREAD_CLIENT_ID!,
        knownAuthorities: import.meta.env.VITE_APP_AZUREAD_KNOWN_AUTHORITIES!.split(' '),
        redirectUri: getBaseUrl(),
    },
});

type useAuthenticationResult = {
    acquireAccessTokenSilent: () => Promise<string>;
    changeEmailRedirect: () => void;
    existsAccount: () => boolean;
    getAccount: () => Partial<AccountInfo> | undefined;
    getAccountContactId: () => string;
    getAccountEmail: () => string;
    getAccountFirstAndLastName: () => {
        firstName: string;
        lastName: string;
    };
    getAccountName: () => string;
    getAccountNameInitials: () => string;
    loginRedirectRegular: () => void;
    logoutRedirectRegular: (goHome?: boolean) => void;
    registerLogoutCallback: () => void;
    signupRedirect: (email: string) => void;
};

const trackException: (appInsights: ApplicationInsights, error: AuthError) => void = (
    appInsights: ApplicationInsights,
    error: AuthError,
) => {
    appInsights.trackException({
        exception: new Error(error.name),
        severityLevel: 3,
        properties: {
            errorCode: error.errorCode,
            errorMessage: error.errorMessage,
            subError: error.subError,
            stack: error.stack,
        },
    });
};

const useAuthentication: () => useAuthenticationResult = () => {
    const { appInsights } = useAppInsights();
    const { instance } = useMsal();
    const { close, registerOnMessage, postMessage } = useBroadcast();

    const registerLogoutCallback: () => void = () => {
        registerOnMessage((msg) => {
            if (msg?.data === 'logout') {
                instance.logout().catch((error) => {
                    if (appInsights) {
                        trackException(appInsights, error);
                    }
                });
                close();
            }
        });
    };

    const existsAccount: () => boolean = () => !!instance.getAllAccounts()?.length;

    const getAccount: () => AccountInfo | undefined = () => instance.getAllAccounts()?.[0];

    const getAccountEmail: () => string = () => {
        return getAccount()?.idTokenClaims?.email as string;
    };

    const getAccountContactId: () => string = () => {
        return getAccount()?.idTokenClaims?.contactId as string;
    };

    const getAccountFirstAndLastName: () => {
        firstName: string;
        lastName: string;
    } = () => {
        const account = getAccount();
        if (!account) {
            return { firstName: '', lastName: '' };
        }

        return {
            firstName: account.idTokenClaims?.firstName as string,
            lastName: account.idTokenClaims?.lastName as string,
        };
    };

    const getAccountName: () => string = () => {
        const { firstName, lastName } = getAccountFirstAndLastName();
        return `${firstName} ${lastName}`.trim();
    };

    const getAccountNameInitials: () => string = () => {
        const { firstName, lastName } = getAccountFirstAndLastName();
        const firstInitial = firstName?.charAt(0)?.toUpperCase() || '';
        const lastInitial = lastName?.charAt(0)?.toUpperCase() || '';

        return firstInitial + lastInitial;
    };

    const logoutRedirectRegular: (goHome?: boolean) => void = (goHome) => {
        const logoutRequest: EndSessionRequest = {
            account: getAccount(),
            authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY!,
            postLogoutRedirectUri: goHome ? getBaseUrl() : window.location.toString(),
        };
        localStorage.removeItem('activeParentBusinessId');

        postMessage('logout');
        instance.logoutRedirect(logoutRequest).catch((error) => {
            if (appInsights) {
                trackException(appInsights, error);
            }
        });
    };

    const signupRedirect: (email: string) => void = (email) => {
        instance
            .loginRedirect({
                ...signupRequest,
                extraQueryParameters: {
                    ...signupRequest.extraQueryParameters,
                    login_hint: encodeURIComponent(email),
                    company_code: getBusinessUnitCode(), // AzureAD takes care of it regardless of what we get from our URL
                },
                redirectStartPage: getBaseUrl(),
            })
            .catch((error) => {
                if (appInsights) {
                    trackException(appInsights, error);
                }
            });
    };

    const loginRedirectRegular: () => void = () => {
        instance
            .loginRedirect({
                ...regularRequest,
                extraQueryParameters: {
                    company_code: getBusinessUnitCode(), // AzureAD takes care of it regardless of what we get from our URL
                },
            })
            .catch((error) => {
                if (appInsights) {
                    trackException(appInsights, error);
                }
            });
    };

    const changeEmailRedirect: () => void = () => {
        instance
            .acquireTokenRedirect({
                ...changeEmailRequest,
                loginHint: getAccountEmail(),
                extraQueryParameters: {
                    company_code: getBusinessUnitCode(), // AzureAD takes care of it regardless of what we get from our URL
                },
            })
            .catch((error) => {
                if (appInsights) {
                    trackException(appInsights, error);
                }
            });
    };

    const acquireAccessTokenSilent: () => Promise<string> = async () => {
        try {
            return (
                await instance.acquireTokenSilent({
                    ...regularRequest,
                    account: getAccount(),
                })
            ).accessToken;
        } catch (error) {
            if (appInsights) {
                trackException(appInsights, error as AuthError);
            }
            return '';
        }
    };

    return {
        acquireAccessTokenSilent,
        changeEmailRedirect,
        existsAccount,
        getAccount,
        getAccountContactId,
        getAccountEmail,
        getAccountFirstAndLastName,
        getAccountName,
        getAccountNameInitials,
        loginRedirectRegular,
        logoutRedirectRegular,
        registerLogoutCallback,
        signupRedirect,
    };
};

export default useAuthentication;
