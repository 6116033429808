import useNotificationsConnection from './useNotificationsConnection';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as signalR from '@microsoft/signalr';
import ApiError from 'classes/ApiError';
import { useAppInsights } from 'contexts/AppInsightsContext';
import { useCallback, useEffect, useRef, useState } from 'react';

export type useSignalRResponseType<U> = {
    connection?: signalR.HubConnection;
    joinGroup: (joinName: string, payload: U) => void;
    isLoading: boolean;
    error?: ApiError;
};

export type useSignalRPropType<T> = {
    listenerName: string;
    onListener: (payload: T) => void;
};

const trackException: (appInsights?: ApplicationInsights | null, error?: Error) => void = (
    appInsights?: ApplicationInsights | null,
    error?: Error,
) => {
    if (error && appInsights) {
        appInsights.trackException({
            exception: error,
            severityLevel: 3,
        });
    }
};

const useSignalR = <T, U>({ onListener, listenerName }: useSignalRPropType<U>): useSignalRResponseType<T> => {
    const { appInsights } = useAppInsights();

    const {
        notificationsConnection,
        isLoading: isLoadingUseNotificationsConnection,
        error: errorUseNotificationsConnection,
    } = useNotificationsConnection();
    const [connection, setConnection] = useState<signalR.HubConnection>();
    const isConnectionInitialized = useRef(false);

    useEffect(() => {
        if (notificationsConnection && !isConnectionInitialized.current) {
            isConnectionInitialized.current = true;
            const newConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
                .withUrl(notificationsConnection.url, {
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => notificationsConnection.accessToken,
                })
                .withAutomaticReconnect()
                .build();

            newConnection.on(listenerName, onListener);

            newConnection.on('NewError', (error) => {
                trackException(appInsights, error);
            });

            newConnection.onreconnecting((error) => {
                trackException(appInsights, error);
            });

            newConnection.onclose((error) => {
                trackException(appInsights, error);
            });

            newConnection.start().then(
                () => {
                    setConnection(newConnection);
                },
                (error) => {
                    if (appInsights) {
                        trackException(appInsights, error);
                    }
                    isConnectionInitialized.current = false;
                },
            );
            return () => {
                if (newConnection?.state === signalR.HubConnectionState.Connected) {
                    newConnection.off(listenerName);
                    newConnection.stop();
                    isConnectionInitialized.current = false;
                }
            };
        }
        return () => {};
    }, [
        onListener,
        listenerName,
        notificationsConnection?.url,
        notificationsConnection?.accessToken,
        notificationsConnection,
        appInsights,
    ]);

    const joinGroup = useCallback(
        async (joinName: string, payload: T): Promise<void> => {
            if (connection) {
                try {
                    await connection.invoke(joinName, payload);
                } catch (error) {
                    if (appInsights && error instanceof Error) {
                        trackException(appInsights, error);
                    }
                }
            }
        },
        [connection, appInsights],
    );

    return {
        connection,
        joinGroup,
        isLoading: isLoadingUseNotificationsConnection,
        error: errorUseNotificationsConnection,
    };
};

export default useSignalR;
