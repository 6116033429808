import useApi from './useApi';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import { IBulkSubscriptions, SubscriptionBulkResult } from 'types/subscription';

export type useSubscriptionBulkResponseType = {
    error?: ApiError;
    isLoading: boolean;
    createSubscriptions: UseMutationResult<SubscriptionBulkResult, ApiError, IBulkSubscriptions>;
};

const useSubscriptionBulk = (): useSubscriptionBulkResponseType => {
    const { post } = useApi<SubscriptionBulkResult, IBulkSubscriptions>();
    const url = `${GENERAL_BFF_PREFIX}/v1/subscription-orders:create-in-bulk`;

    const queryClient = useQueryClient();

    const createSubscriptions = useMutation<SubscriptionBulkResult, ApiError, IBulkSubscriptions>({
        mutationFn: (subscriptionOrders: IBulkSubscriptions) => post(url, subscriptionOrders),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [url] }),
    });

    return {
        error: createSubscriptions.error ?? undefined,
        isLoading: createSubscriptions.isPending,
        createSubscriptions,
    };
};

export default useSubscriptionBulk;
