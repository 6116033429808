import './i18n';
import './index.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FirebaseErrorComponent from 'components/FirebaseErrorComponent';
import ToastsWrapper from 'components/info/ToastsWrapper';
import ScrollToTop from 'components/ScrollToTop';
import AppRoutesWrapper from 'components/wrappers/AppRoutesWrapper';
import { AppInsightsProvider } from 'contexts/AppInsightsContext';
import { ErrorProvider } from 'contexts/ErrorContext';
import { ModalProvider } from 'contexts/Modal';
import { ToastProvider } from 'contexts/Toast';
import { initRemoteConfig } from 'firebase/firebaseConfig';
import { pca } from 'hooks/useAuthentication';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from 'styles/globalStyle';

const trackingArgs = {
    trackingId: import.meta.env.VITE_APP_GOOGLE_ANALYTICS4_ID || 'missing-google-analytics4-id',
    gtagOptions: { debug_mode: import.meta.env.VITE_APP_GOOGLE_ANALYTICS4_DEBUG || false },
};

ReactGA.initialize([trackingArgs]);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            retry: false,
        },
    },
});

function App(): JSX.Element {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const initializeConfig = async (): Promise<void> => {
            try {
                await initRemoteConfig();
            } catch {
                setHasError(true);
            }
        };

        initializeConfig();
    }, []);

    return (
        <BrowserRouter>
            <AppInsightsProvider>
                <QueryClientProvider client={queryClient}>
                    <ToastProvider>
                        <ModalProvider>
                            <ErrorProvider>
                                {hasError ? (
                                    <FirebaseErrorComponent />
                                ) : (
                                    <MsalProvider instance={pca}>
                                        <ScrollToTop />
                                        <GlobalStyle />
                                        <AppRoutesWrapper />
                                        <ToastsWrapper />
                                    </MsalProvider>
                                )}
                            </ErrorProvider>
                        </ModalProvider>
                    </ToastProvider>
                </QueryClientProvider>
            </AppInsightsProvider>
        </BrowserRouter>
    );
}

export default App;
