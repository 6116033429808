import { useCountrySpecificContent } from './useCountrySpecificContent';
import { MAX_DATE_RANGE } from 'constants/general';
import { differenceInCalendarDays, isAfter, isValid, parse } from 'date-fns';
import { useCallback } from 'react';

export type useDateValidationReturnType = {
    isValidDate: (dateString: string) => boolean;
    isDateAfterToday: (dateString: string) => boolean;
    isFromDateAfterToDate: (fromDate: string, toDate: string) => boolean;
    isValidDateRange: (fromDate: string, toDate: string) => boolean;
};

const useDateValidation = (): useDateValidationReturnType => {
    const { dateFormatRegExp, dateFormat } = useCountrySpecificContent();

    const isValidDate = useCallback(
        (dateString: string): boolean => {
            if (!dateFormatRegExp.exec(dateString)) {
                return false;
            }

            const parsedDate = parse(dateString, dateFormat, new Date());
            return isValid(parsedDate);
        },
        [dateFormatRegExp, dateFormat],
    );

    const isDateAfterToday = useCallback(
        (dateString: string): boolean => {
            if (!dateString) {
                return false;
            }

            const parsedDate = parse(dateString, dateFormat, new Date());
            return isAfter(parsedDate, new Date());
        },
        [dateFormat],
    );

    const isFromDateAfterToDate = useCallback(
        (fromDate: string, toDate: string): boolean => {
            if (!fromDate || !toDate) {
                return false;
            }

            const parsedFromDate = parse(fromDate, dateFormat, new Date());
            const parsedToDate = parse(toDate, dateFormat, new Date());

            if (isAfter(parsedFromDate, parsedToDate)) {
                return true;
            }

            return false;
        },
        [dateFormat],
    );

    const isValidDateRange = useCallback(
        (fromDate: string, toDate: string): boolean => {
            if (!fromDate || !toDate) {
                return false;
            }

            const parsedFromDate = parse(fromDate, dateFormat, new Date());
            const parsedToDate = parse(toDate, dateFormat, new Date());

            if (differenceInCalendarDays(parsedToDate, parsedFromDate) > MAX_DATE_RANGE) {
                return false;
            }
            return true;
        },
        [dateFormat],
    );

    return {
        isValidDate,
        isDateAfterToday,
        isFromDateAfterToDate,
        isValidDateRange,
    };
};

export default useDateValidation;
