import DeleteIcon from 'assets/icons/cross.svg?react';
import IconButton from 'components/clickables/IconButton';
import FieldErrorMessage from 'components/forms/FieldError';
import { MAX_CHARACTER_LENGTH } from 'constants/general';
import { ChangeEvent, ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import { FieldError, FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    backgroundColorDisabled,
    black,
    borderColorDisabled,
    borderColorGrey,
    borderRadiusM,
    darkGrey,
    font,
    primaryMerBlue,
    red,
    screenWidthMini,
    shadowBlueSharp,
    spaceL,
    spaceM,
    spaceS,
    spaceXs,
    spaceXxl,
    textColorDisabled,
    white,
} from 'styles/variables';
import { ButtonType } from 'types';
import uuid from 'utils/uuid';

export interface Props {
    id?: string;
    name: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    autoComplete?: string;
    label?: string;
    width?: string;
    className?: string;
    flexGrow?: number;
    fieldError?: FieldError | null;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onClearFieldClick?: () => void;
    placeholder?: string;
    value?: string;
    StartIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    dataTestId?: string;
    infoMessage?: React.ReactNode;
    hideOptionalText?: boolean;
    displayErrorTextOnOneLine?: boolean;
}

export const Container = styled.div<{
    width: string;
    $flexGrow: number;
    disabled: boolean;
    $error: boolean;
    required: boolean;
    readOnly: boolean;
    margin?: string;
    $startIcon?: boolean;
    $label?: string;
}>`
    display: flex;
    flex-direction: column;
    flex-grow: ${({ $flexGrow }) => $flexGrow};
    position: relative;
    margin-bottom: ${({ margin }) => margin ?? spaceM};

    @media screen and (min-width: ${screenWidthMini}) {
        width: ${({ width }) => width};
    }

    label {
        color: ${black};
        font-size: ${font.size.m};
        font-weight: ${font.weight.semiBold};
        line-height: ${font.lineHeight.s};
        margin-bottom: ${spaceXs};
        transition: color 0.3s ease-in-out;
    }

    input {
        padding: ${({ $startIcon }) => `0 ${spaceXxl} 0  ${$startIcon ? spaceXxl : spaceS}`};
        width: 100%;
        height: 3rem;
        color: ${black};
        background-color: ${white};
        border-width: 2px;
        border-style: solid;
        border-color: ${({ $error }) => ($error ? red : borderColorGrey)};
        border-radius: ${borderRadiusM};
        font-weight: ${font.weight.regular};
        font-family: ${font.body};
        font-size: ${font.size.m};
        transition: all 0.3s ease-in-out;
        outline: none;

        &:focus {
            box-shadow: ${shadowBlueSharp};
            border: 2px solid ${primaryMerBlue};
        }

        &:disabled,
        &:read-only {
            color: ${textColorDisabled};
            border-color: ${borderColorDisabled};
            background-color: ${backgroundColorDisabled};
        }
    }
    input::placeholder {
        color: ${darkGrey};
    }

    .adornment {
        position: absolute;
        right: 0.5rem;
        top: ${({ $label }) => ($label ? spaceL : spaceXs)};
        text-align: right;
    }
    .startIcon {
        position: absolute;
        left: 0;
        height: 100%;
        padding: 0 ${spaceS};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const OptionalText = styled.span`
    margin-left: ${spaceXs};
    color: ${darkGrey};
`;

const onAdornmentClick = (
    name: string,
    onClearFieldClick?: () => void,
    formContext?: UseFormReturn<FieldValues>,
): void => {
    if (onClearFieldClick) onClearFieldClick();
    if (formContext) {
        formContext.setValue(name, '', { shouldDirty: true });
        formContext.setFocus(name);
    }
};

const TextInput: React.FC<Props> = forwardRef(
    (
        {
            id = uuid(),
            name,
            minLength,
            maxLength = MAX_CHARACTER_LENGTH,
            type = 'text',
            required = false,
            disabled = false,
            readOnly = false,
            autoComplete,
            label,
            width = '100%',
            fieldError,
            flexGrow = 1,
            className,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            onClearFieldClick,
            placeholder,
            value,
            StartIcon,
            dataTestId,
            infoMessage,
            hideOptionalText = false,
            displayErrorTextOnOneLine = false,
        }: Props,
        ref: ForwardedRef<never>,
    ) => {
        const { t } = useTranslation();
        const showOptionalText = label && !required && !readOnly && !hideOptionalText;
        const formContext = useFormContext();

        const isFieldNotEmpty = !!formContext?.watch(name);

        const [isCrossVisible, setIsCrossVisible] = useState<boolean>(false);
        const [isFocused, setIsFocused] = useState<boolean>(false);
        const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);

        useEffect(() => {
            if (disabled) {
                setIsCrossVisible(false);
                setIsFocused(false);
            }
        }, [disabled]);

        return (
            <Container
                onMouseOver={() => setIsCrossVisible(isFieldNotEmpty)}
                onMouseLeave={() => !isFocused && setIsCrossVisible(false)}
                onBlur={() => {
                    setIsFocused(false);
                    if (!isKeyPressed) {
                        setIsCrossVisible(false);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                        setIsKeyPressed(true);
                    }
                }}
                width={width}
                $flexGrow={flexGrow}
                disabled={disabled}
                required={required}
                readOnly={readOnly}
                $error={!!fieldError}
                className={className}
                $startIcon={!!StartIcon}
                $label={label}
            >
                {label && (
                    <label htmlFor={id} data-testid={dataTestId ? `label_${dataTestId}` : ''}>
                        {label}
                        {showOptionalText && <OptionalText>{`(${t('form.input.optionalFieldText')})`}</OptionalText>}
                    </label>
                )}
                <input
                    id={id}
                    data-testid={dataTestId}
                    ref={ref}
                    minLength={minLength}
                    maxLength={maxLength}
                    name={name}
                    type={type}
                    readOnly={readOnly}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (onChange) onChange(e);
                        setIsCrossVisible(true);
                    }}
                    onFocus={(e) => {
                        if (onFocus) onFocus(e);
                        setIsCrossVisible(isFieldNotEmpty);
                        setIsFocused(true);
                        setIsKeyPressed(false);
                    }}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    value={value}
                    autoComplete={autoComplete}
                    aria-required={required ? 'true' : 'false'}
                    aria-invalid={fieldError ? 'true' : 'false'}
                />
                {StartIcon ? (
                    <span className="startIcon">
                        <StartIcon />
                    </span>
                ) : null}
                {(isCrossVisible && !disabled) || (isFocused && isFieldNotEmpty) ? (
                    <span className="adornment">
                        <IconButton
                            onMouseDown={(e) => e.preventDefault()}
                            onBlur={() => {
                                setIsCrossVisible(false);
                            }}
                            data-testid={`clearButton_${name}`}
                            type={ButtonType.BUTTON}
                            onClick={() => {
                                onAdornmentClick(name, onClearFieldClick, formContext);
                                setIsCrossVisible(false);
                                setIsFocused(false);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </span>
                ) : null}
                {fieldError ? (
                    <FieldErrorMessage
                        displayErrorTextOnOneLine={displayErrorTextOnOneLine}
                        dataTestId={dataTestId}
                        fieldError={fieldError}
                    />
                ) : null}
                {infoMessage}
            </Container>
        );
    },
);
export default memo(TextInput);
