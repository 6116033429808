import Button from './Button';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'types';

type FilterButtonProps = {
    showFilter: () => void;
    className?: string;
    dataTestId?: string;
};

function FilterButton({ showFilter, className, dataTestId }: FilterButtonProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <Button dataTestId={dataTestId} onClick={showFilter} className={className} variant={ButtonVariant.SECONDARY}>
            {t('general.filter')}
        </Button>
    );
}

export default FilterButton;
