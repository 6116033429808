import Tippy from '@tippyjs/react';
import InfoIcon from 'assets/icons/question_mark_solid.svg?react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusS, primaryMerBlue, spaceXs, spaceXxs } from 'styles/variables';
import { ETrackingEvent, IPopupTracking } from 'types/tracking';

const TippyTool = styled.span`
    display: inline-block;
    .tippyButton {
        background: transparent;
        border: none;
        border-radius: ${borderRadiusS};
        line-height: 0;
        width: max-content;
        height: max-content;
        color: ${primaryMerBlue};
        font-size: inherit;
    }
`;

const ReadMore = styled.span`
    display: inline-block;
    padding: 0 ${spaceXxs};
    line-height: 1;
`;

const IconContainer = styled.div`
    padding: ${spaceXs} ${spaceXs} ${spaceXs} 0;
`;

type PopUpProp = {
    icon?: React.ReactNode;
    readMore?: boolean;
    content?: React.ReactNode | React.ReactNode[];
    dataTestId?: string;
    gaTracking?: IPopupTracking;
};

function PopUp({ icon, content, dataTestId, gaTracking, readMore }: PopUpProp): JSX.Element {
    const { t } = useTranslation();

    const popUpTrigger = readMore ? (
        <ReadMore>{t('general.readMore')}</ReadMore>
    ) : (
        <IconContainer>{icon ?? <InfoIcon data-testid="defaultIcon" />}</IconContainer>
    );

    return (
        <TippyTool>
            <Tippy
                hideOnClick="toggle"
                arrow={false}
                theme="light"
                placement="bottom-end"
                className="tippy"
                allowHTML
                maxWidth="26rem"
                content={content}
                onShow={() => {
                    if (gaTracking) {
                        ReactGA.event(ETrackingEvent.INFO_POPUP, {
                            type: gaTracking.type,
                            origin: gaTracking.origin,
                        });
                    }
                }}
            >
                <button
                    type="button"
                    className="tippyButton"
                    data-testid={dataTestId ?? 'popupButton'}
                    aria-label={t('general.readMore')}
                >
                    {popUpTrigger}
                </button>
            </Tippy>
        </TippyTool>
    );
}

export default PopUp;
