import { Business } from './business';
import { Address, ChangeRecord, ConsentFormType, Contact, ECurrency, EDeliveryOption, PaginatedResponse } from 'types';

export interface IBulkSubscriptionType extends Omit<CreateSubscriptionFormInputType, 'type'> {
    error?: boolean;
    row?: number;
    type?: string;
    shipToPostalAddressId?: string;
    errorType?: ESubscriptionBulkError[];
}

export type BulkSubscriptionType = {
    businessId: string;
    row: number;
    contact?: {
        firstName: string;
        lastName: string;
        email: string;
        countryCode: string;
    };
    licencePlate: string;
    productName: string;
    reference: string;
    deliveryOption: string;
    countryCode: string;
    shipToPostalAddressId?: string;
};

export interface IBulkSubscriptions {
    subscriptionOrders: BulkSubscriptionType[];
}

export enum ESubscriptionBulkError {
    DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
    IMPORT_ERROR = 'IMPORT_ERROR',
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    USER_HAS_ACTIVE_DRIVER_CONTRACT = 'USER_HAS_ACTIVE_DRIVER_CONTRACT',
}

export type SubscriptionBulkResponse = {
    row: number;
    created: boolean;
    errorType?: ESubscriptionBulkError[];
};

export type SubscriptionBulkResult = {
    subscriptionOrderResponses: SubscriptionBulkResponse[];
};

export interface SubscriptionsResult extends PaginatedResponse {
    subscriptions: Subscription[];
}

export type Subscription = {
    id?: string;
    type?: string;
    unknownDriver: boolean;
    driverName?: string;
    driver?: Contact;
    licencePlate: string;
    reference: string;
    businessId?: string;
    businessName?: string;
    deliveryOption: EDeliveryOption | '';
    referenceChangeRecord?: ChangeRecord[];
    licencePlateChangeRecord?: ChangeRecord[];
    deliveryContact?: Contact;
    deliveryAddress?: Address;
    productType: string;
    isActive: boolean;
    rfids?: string[];
    startDate?: string | Date;
    endDate?: string | Date;
    status?: ESubscriptionStatus;
};

export type SubscriptionOrder = {
    id: string;
    name: string;
    status: ESubscriptionOrderStatus;
    businessId: string;
    businessName: string;
    reference: string;
    licencePlate: string;
    creationTime: string | Date;
    unknownDriver: boolean;
    driver?: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        countryCode: string;
    };
};

export interface SubscriptionOrdersResult extends PaginatedResponse {
    subscriptionOrders: SubscriptionOrder[];
}

export type CreateSubscriptionFormInputType = {
    id?: string;
    type: string;
    unknownContact: boolean;
    contact?: Contact;
    licencePlate: string;
    reference: string;
    deliveryOption: EDeliveryOption | '';
    organisationId?: string; // temporary,
    organisation?: Business;
};

export type CreateSubscriptionOrderType = {
    contact?: Contact;
    campaignId: string;
    licencePlate: string;
    reference: string;
    productId: string;
    shipToPostalAddressId?: string;
    deliveryOption: EDeliveryOption | '';
    countryCode: string;
};

export interface ISubscriptionSubmit extends CreateSubscriptionFormInputType {
    businessId: string;
}

export type SubscriptionOrderSubmitType = {
    subscriptions: ISubscriptionSubmit[];
};

export enum EElectricCurrentType {
    AC = 'AC',
    DC = 'DC',
    UFC = 'UFC',
}

export enum ESubscriptionFormType {
    PERSONALISED = 'personalised',
    UNPERSONALISED = 'unpersonalised',
}

export enum ESubscriptionStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    CANCELLED = 'CANCELLED',
    RECEIVED = 'RECEIVED',
}

export enum ESubscriptionOrderStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

export enum ESubscriptionSortingColumns {
    CREATION_TIME = 'CREATION_TIME',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
}

export type UpdateSubscriptionFormInputType = {
    licencePlate: string;
    reference: string;
};

export type DeliveryAddressFormType = ConsentFormType & {
    deliveryContact: Contact;
    deliveryAddress: Address;
};

export type SubscriptionSummary = {
    type: string;
    unknownContact: boolean;
    amount: number;
    monthlyFee: {
        currency: ECurrency;
        includingVat: number;
        excludingVat: number;
    };
};

export type DeliveryDetailsSummary = {
    type: string;
    amount: number;
};

export type TotalToPay = {
    currency: ECurrency;
    includingVat: number;
    excludingVat: number;
};

export interface IAddressSummary {
    name: string;
    address: Address;
}

export type SubscriptionOrderSummary = {
    subscriptionSummaries: SubscriptionSummary[];
    deliveryDetails: DeliveryDetailsSummary[];
    addresses: IAddressSummary[];
    totalToPay: TotalToPay;
};

/** *********************
 * STATISTICS
 ********************* */

export interface ChargeDetails {
    id: string;
    businessId: string;
    startTime: string;
    endTime: string;
    driverName: string;
    licencePlate?: string;
    cardVisibleNumber: string;
    subscriptionType: string;
    reference: string;
    siteName: string;
    totalCost: number;
    consumedEnergy: number;
    chargeTime: string;
    location: EUsageType;
    currentType: EElectricCurrentType;
    roamingPartnerName?: string;
    brandType?: EBrandType;
    currency: ECurrency;
}

export enum EBrandType {
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    PHYSICAL_CARD = 'PHYSICAL_CARD',
    VIN = 'VIN',
    EXTERNAL = 'EXTERNAL',
}

export interface ChargeDetailsResult extends PaginatedResponse {
    billingTransactions: ChargeDetails[];
}

export enum EUsageType {
    HOME = 'HOME',
    PUBLIC = 'PUBLIC',
    INTERNAL = 'INTERNAL',
    ROAMING = 'ROAMING',
}

export type Charger = {
    name: string;
    address: {
        id: string;
        country: string;
        region: string;
        city: string;
        postalCode: string;
        addressLine1: string;
        addressLine2: string;
    };
    consumedEnergy: number;
};

export type ConsumedEnergy = {
    name: string;
    kWh: number;
};

export type ActiveSubscription = {
    type: string;
    amount: number;
};

export type ConsumedEnergyForType = {
    name: EElectricCurrentType;
    kWh: number;
};
export type ChartEntries = {
    year: number;
    month: number;
    day?: number;
    consumedEnergyForLocations: ConsumedEnergy[];
};

export type ChargePoints = {
    name: string;
    consumedEnergy: number;
    address: {
        id: string;
        country: string;
        region: string;
        city: string;
        postalCode: string;
        addressLine1: string;
        addressLine2: string;
    };
};

export type CostItem = {
    amount: number;
    currency: string;
};

export type DynamicKpiData = {
    consumedEnergyChartEntries: ChartEntries[];
    consumedEnergyForLocations: ConsumedEnergy[];
    consumedEnergyForSubscriptionTypes: ConsumedEnergy[];
    consumedEnergyForRoamingPartners: ConsumedEnergy[];
    consumedEnergyForCurrentType: ConsumedEnergyForType[];
    publicLocationCost: CostItem[];
    internalLocationCost: CostItem[];
    variableCost: CostItem[];
    drivenKilometers: number;
    usedChargePoints: ChargePoints[];
};
