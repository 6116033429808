import styled from 'styled-components';
import { font, spaceXs } from 'styles/variables';

const Container = styled.section`
    margin-bottom: 2.5rem;
`;

const SectionTitle = styled.h2<{ $hasExtraInfo: boolean }>`
    font-size: ${font.size.l};
    line-height: ${font.lineHeight.l};
    margin-bottom: ${({ $hasExtraInfo }) => ($hasExtraInfo ? spaceXs : '0.75rem')};
`;

const ExtraInfo = styled.p`
    margin-bottom: 0.75rem;
`;

type SectionContainerProps = {
    title: string;
    children: React.ReactNode;
    className?: string;
    dataTestId?: string;
    extraInfo?: React.ReactNode;
};

function SectionContainer({ title, children, className, dataTestId, extraInfo }: SectionContainerProps): JSX.Element {
    return (
        <Container className={className} data-testid={dataTestId}>
            <SectionTitle $hasExtraInfo={!!extraInfo}>{title}</SectionTitle>
            {extraInfo && <ExtraInfo>{extraInfo}</ExtraInfo>}
            {children}
        </Container>
    );
}

export default SectionContainer;
