import MessageBox from './MessageBox';
import MessageIcon from 'assets/illustrations/ic_renewable_energy.svg?react';
import { t } from 'i18next';
import styled from 'styled-components';
import { font, spaceL, spaceXxl } from 'styles/variables';

const StyledMessageBox = styled(MessageBox)`
    &.small {
        padding: ${spaceXxl} ${spaceL};

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: ${font.size.m};
            margin-bottom: 0;
        }

        svg {
            height: 90px;
        }
    }
`;

export enum EMessageSize {
    SMALL = 'small',
    REGULAR = 'regular',
}

type EmptyListMessageProps = {
    Illustration?: React.ReactNode;
    title?: string;
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    size?: EMessageSize;
    displayBorder?: boolean;
};

function EmptyListMessage({
    Illustration = <MessageIcon />,
    title = t('infoMessage.emptyList.title'),
    tag = 'h3',
    size = EMessageSize.REGULAR,
    displayBorder = false,
}: EmptyListMessageProps): JSX.Element {
    return (
        <StyledMessageBox
            dataTestId="emptyListMessage"
            Illustration={Illustration}
            title={title}
            displayBorder={displayBorder}
            tag={tag}
            className={size}
        />
    );
}

export default EmptyListMessage;
