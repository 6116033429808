import ChargerIcon from 'assets/icons/charger.svg?react';
import EmptyListMessage, { EMessageSize } from 'components/info/EmptyListMessage';
import { useNumber } from 'hooks/useNumber';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    borderColorBlue,
    borderRadiusM,
    darkerGrey,
    font,
    mediumBlue,
    panelBackgroundColorBlue,
    primaryMerBlue,
    spaceM,
    spaceS,
    spaceXs,
    white,
} from 'styles/variables';
import { Charger } from 'types/subscription';

const Container = styled.section`
    grid-column: 1 / span 2;
    background: ${panelBackgroundColorBlue};
    border-radius: ${borderRadiusM};
    padding: ${spaceM};
    h2 {
        font-size: ${font.size.l};
        margin-bottom: ${spaceS};
    }
    ol {
        list-style: none;
    }
    p {
        margin: 0;
    }
`;

const ListItem = styled.li`
    border: 1px solid ${borderColorBlue};
    background: ${white};
    padding: ${spaceXs} ${spaceS};
    display: grid;
    grid-template-columns: 2.5rem 1fr auto;
    gap: ${spaceS};
    align-items: center;
    &:not(:last-child) {
        margin-bottom: ${spaceXs};
    }
`;

const IconContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: ${borderRadiusM};
    background: ${mediumBlue};
    color: ${primaryMerBlue};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Text = styled.div`
    span {
        display: block;
        font-size: ${font.size.s};
        color: ${darkerGrey};
    }
`;
const Kwh = styled.p`
    align-self: start;
    font-size: ${font.size.s};
    span {
        font-size: ${font.size.m};
    }
`;

type MostUsedChargersProps = {
    chargerPoints: Charger[];
};

function MostUsedChargers({ chargerPoints }: MostUsedChargersProps): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    return (
        <Container>
            <h2>{t('subscription.statistics.kpi.chargers.title')}</h2>
            {chargerPoints.length === 0 ? (
                <EmptyListMessage size={EMessageSize.SMALL} />
            ) : (
                <ol data-testid="mostUsedChargers">
                    {chargerPoints.map((item) => {
                        return (
                            <ListItem key={item.name}>
                                <IconContainer>
                                    <ChargerIcon />
                                </IconContainer>
                                <Text>
                                    <p>
                                        {item.name}
                                        <span>
                                            {item.address.addressLine1}, {item.address.postalCode} {item.address.city}
                                        </span>
                                    </p>
                                </Text>
                                <Kwh>
                                    <span>{formatNumber(item.consumedEnergy)}</span> kWh
                                </Kwh>
                            </ListItem>
                        );
                    })}
                </ol>
            )}
        </Container>
    );
}

export default MostUsedChargers;
