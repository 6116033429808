import PopUp from './PopUp';
import TooltipContent from './TooltipContent';
import CarIcon from 'assets/icons/car_side.svg?react';
import OrganisationIcon from 'assets/icons/company.svg?react';
import DeliveryIcon from 'assets/icons/delivery.svg?react';
import EmailIcon from 'assets/icons/envelope.svg?react';
import LocationIcon from 'assets/icons/location_pin.svg?react';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import ReferenceIcon from 'assets/icons/speech_bubble.svg?react';
import SubscriptionIcon from 'assets/icons/subscription_type.svg?react';
import UserIcon from 'assets/icons/user.svg?react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceS, spaceXs, spaceXxs } from 'styles/variables';
import { ETrackingOrigin, ETrackingType } from 'types/tracking';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spaceS};
    li {
        display: grid;
        grid-template-columns: 1rem 1fr;
        gap: ${spaceXs};
        align-items: start;
        margin-bottom: ${spaceXs};
        svg {
            margin-top: ${spaceXxs};
            height: 1rem;
            justify-self: center;
        }
    }
`;

function BulkImportInfoPopup(): JSX.Element {
    const { t } = useTranslation();
    return (
        <PopUp
            dataTestId="iconInfoButton"
            gaTracking={{
                type: ETrackingType.LEGEND_INFO,
                origin: ETrackingOrigin.SUBSCRIPTION_IMPORT,
            }}
            content={
                <TooltipContent
                    headline={t('subscription.iconDescription.title')}
                    content={
                        <Container data-testid="iconInfoList">
                            <ul>
                                <li>
                                    <UserIcon />
                                    <span>{t('subscription.iconDescription.driverName')}</span>
                                </li>
                                <li>
                                    <EmailIcon />
                                    <span>{t('general.email')}</span>
                                </li>
                                <li>
                                    <CarIcon />
                                    <span>{t('subscription.iconDescription.licencePlate')}</span>
                                </li>

                                <li>
                                    <ReferenceIcon />
                                    <span>{t('subscription.iconDescription.reference')}</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <DeliveryIcon />
                                    <span>{t('subscription.iconDescription.locationType')}</span>
                                </li>
                                <li>
                                    <LocationIcon />
                                    <span>{t('subscription.iconDescription.address')}</span>
                                </li>
                                <li>
                                    <OrganisationIcon />
                                    <span>{t('subscription.iconDescription.organisation')}</span>
                                </li>
                                <li>
                                    <SubscriptionIcon />
                                    <span>{t('subscription.iconDescription.subscription')}</span>
                                </li>
                            </ul>
                        </Container>
                    }
                    icon={<QuestionMarkTooltipIcon />}
                />
            }
        />
    );
}

export default BulkImportInfoPopup;
