import useApi from './useApi';
import useBusinessId from './useBusinessId';
import { useCountrySpecificContent } from './useCountrySpecificContent';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX, PAGE_SIZE } from 'constants/general';
import { endOfDay, parse } from 'date-fns';
import { ChargeDetailsResult } from 'types/subscription';

export type SubscriptionChargeDetailsResponse = {
    chargeDetailsList?: ChargeDetailsResult;
    isLoading: boolean;
    error?: ApiError;
};

type GetChargingHistoryParams = {
    businessId?: string;
    pageSize?: number;
    pageNumber?: number;
    searchTerm?: string;
    dateFrom?: string;
    dateTo?: string;
};

function useSubscriptionChargeDetails(prop: GetChargingHistoryParams): SubscriptionChargeDetailsResponse {
    const { businessId, pageNumber = 0, pageSize = PAGE_SIZE, searchTerm, dateFrom, dateTo } = prop;
    const { dateFormat } = useCountrySpecificContent();
    const { activeParentBusinessId } = useBusinessId();

    const paramsArray: string[][] = [];
    paramsArray.push(['pageSize', pageSize.toString()]);
    paramsArray.push(['pageNumber', pageNumber.toString()]);

    if (searchTerm) {
        paramsArray.push(['searchTerm', searchTerm]);
    }
    if (dateFrom && dateTo) {
        paramsArray.push(['dateFrom', parse(dateFrom, dateFormat, new Date()).toISOString()]);
        paramsArray.push(['dateTo', endOfDay(parse(dateTo, dateFormat, new Date())).toISOString()]);
    }
    if (businessId) {
        paramsArray.push(['businessId', businessId]);
    }

    const { post } = useApi<ChargeDetailsResult, object>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${activeParentBusinessId}/billing-transactions?${urlParams}`;
    const fetcher: () => Promise<ChargeDetailsResult> = () => post(url, {});

    const {
        data: chargeDetailsList,
        isFetching: isLoading,
        error,
    } = useQuery<ChargeDetailsResult, ApiError>({
        queryKey: [`${url}?${businessId}`],
        queryFn: fetcher,
        enabled: !!activeParentBusinessId,
    });

    return {
        chargeDetailsList,
        isLoading,
        error: error ?? undefined,
    };
}

export default useSubscriptionChargeDetails;
