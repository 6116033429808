import useApi from './useApi';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { RESET_BFF_ENDPOINT_PREFIX } from 'constants/general';

type useResetChargerTypeResponse = {
    error?: ApiError;
    isLoading: boolean;
    resetCharger: UseMutationResult<undefined, unknown, string, unknown>;
};

const useResetCharger = (): useResetChargerTypeResponse => {
    const { post } = useApi();
    let url = '';

    const queryClient = useQueryClient();

    const resetCharger = useMutation({
        mutationFn: (chargerId: string) => {
            url = `${RESET_BFF_ENDPOINT_PREFIX}/v1/chargers/${chargerId}:reset`;
            return post(url, undefined);
        },

        onSuccess: () => queryClient.invalidateQueries({ queryKey: [url] }),
    });

    return {
        error: (resetCharger.error as ApiError) ?? undefined,
        isLoading: resetCharger.isPending,
        resetCharger,
    };
};

export default useResetCharger;
