import ExpandibleMenuItem from './ExpandibleMenuItem';
import MenuItem from './MenuItem';
import { MenuListType } from './NavigationMenu';

type ExpandedNavigationMenuProps = {
    item: MenuListType;
};

function ExpandedNavigationMenu({ item }: ExpandedNavigationMenuProps): JSX.Element {
    return (
        <li className={`navigationMenu_${item.name}`} data-testid={`expandedNavigationMenu_${item.name}`}>
            {item.items.length ? <ExpandibleMenuItem item={item} /> : <MenuItem item={item} />}
        </li>
    );
}

export default ExpandedNavigationMenu;
