import useApi from './useApi';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import { IProperty } from 'types/facility';

type usePropertiesResult = {
    properties: IProperty[];
    isLoading: boolean;
    error?: ApiError;
};
type PropertiesResult = {
    properties: IProperty[];
};

const useProperties = (businessId: string): usePropertiesResult => {
    const { get } = useApi<PropertiesResult>();

    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/properties`;
    const fetcher: () => Promise<PropertiesResult> = () => get(url);

    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<PropertiesResult, ApiError>({ queryKey: [url], queryFn: fetcher, enabled: !!businessId });

    const properties = data?.properties ?? [];

    return {
        properties,
        isLoading,
        error: error ?? undefined,
    };
};

export default useProperties;
