import {
    black,
    borderRadiusM,
    borderRadiusS,
    cyanBlue,
    darkBlue,
    darkerBlue,
    darkerGrey,
    defaultButtonHeight,
    font,
    lightBlue,
    lighterGrey,
    mediumBlue,
    primaryMerBlue,
    shadowBlueSharp,
    smallButtonHeight,
    spaceS,
    spaceXs,
    white,
} from './variables';
import { ButtonSize, ButtonVariant } from 'types';

export const MerButtonStyle = `
    color: ${white};
    background-color: ${primaryMerBlue};
    border: none;
    border-radius: ${borderRadiusM};

    font-family: ${font.semiBold};
    display: flex;
    justify-content: center;
    gap: ${spaceXs};
    align-items: center;

    font-size: ${font.size.m};
    padding: 0 ${spaceS};
    height: ${defaultButtonHeight};
    min-width: 5rem;

    cursor: pointer;

    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: ${darkBlue};
    }

    &:focus {
        background-color: ${darkBlue};
        box-shadow: ${shadowBlueSharp};
        &:not(:focus-visible) {
            //This removed focus on interaction with mouse but kept focus on keyboard navigation
            outline: 0;
            box-shadow: none;
        }
    }

    &:active {
        background-color: ${darkerBlue};
        box-shadow: none;
    }

    &:disabled {
        cursor: not-allowed;
        box-shadow: none;
        &:not(.loading) {
        opacity: 0.5;
        }
    }
    
    &.btn--size-${ButtonSize.SMALL} {
        font-size: ${font.size.s};
        padding: 0 ${spaceXs};
        height: ${smallButtonHeight};
        min-width: auto;
        border-radius: ${borderRadiusS};
    }

    &.btn--${ButtonVariant.PRIMARY} {
        color: ${white};
        background: ${primaryMerBlue};

        &:hover:not(:disabled) {
            background: ${darkBlue};
        }

        &:active:not(:disabled) {
            background: ${darkerBlue};
        }

        &.loading svg circle {
            stroke: ${white};
        }
    }

    &.btn--${ButtonVariant.SECONDARY} {
        color: ${black};
        background-color: ${lightBlue};

        &:hover:not(:disabled) {
            background-color: ${mediumBlue};
        }

        &:active:not(:disabled) {
            background-color: ${cyanBlue};

        }
    }

    &.btn--${ButtonVariant.TEXT} {
        color: ${primaryMerBlue};
        background-color: transparent;
        padding:0;
        min-width: auto;

        &:hover {
            box-shadow: none;
            color: ${darkBlue};
        }

        &:focus {
            background-color: ${lighterGrey};
            color: ${darkBlue};
        }

        &:active {
            background-color: ${lighterGrey};
            color: ${darkerBlue};
        }

        &:disabled {
            color: ${darkerGrey};

            &:hover {
                background-color: transparent;
            }
        }
    }
`;

export const ButtonGroupStyle = `
    cursor: pointer;
    user-select: none;
    font-size: ${font.size.m};
    font-family: ${font.semiBold};
    border: 0;
    height: 48px;
    padding: 0 20px; 
    color: ${black};
    background-color: ${lightBlue};
    
    &:first-child {
        border-radius: ${borderRadiusM} 0 0 ${borderRadiusM};
        border-right: 1px solid ${mediumBlue};        
    }

    &:last-child {
        border-radius: 0 ${borderRadiusM} ${borderRadiusM} 0;
        border-left: 1px solid ${mediumBlue};
    }

    &.checked {
        color: ${white};
        background-color: ${primaryMerBlue};
    }

    &:hover:not(.checked):not(:disabled) {
        background-color: ${mediumBlue};
    }

    &:focus {
        box-shadow: none;
    }

    &:active:not(:disabled) {
        background-color: ${cyanBlue};
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const textEllipsis = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
